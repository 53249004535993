
import Header from '../../../components/layout/Header'

import { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'


import {
    PlayIcon
  } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { supabase } from '../../../helpers/supabase'
import { TeamContext } from '../../../helpers/TeamContext'

const statuses :any = {
ACTIVE: 'text-white bg-green-700 ring-green-600/20',
DRAFT:'text-white bg-orange-950 ring-orange-600/10',
}


function classNames(...classes:any) {
return classes.filter(Boolean).join(' ')
}

    
export default function AutomationFlows() {

    const {teamId} = useContext(TeamContext)
    const [automationflows,setAutomationflows] = useState([])

    useEffect(() => {
        async function getFlows(){
            let {data,errors} : any = await supabase.from("automation_flows").select("*").eq("team_id",teamId).order("created_at",{ascending:false})

            if (data !== null && data.length >= 1){
                setAutomationflows(data)
            }
        }
        getFlows()
    },[])

    return (
    <>
        <Header title="Automation flows">
            <Link to="/automation-flows/new"
                className="w-30 text-center rounded-md bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-slate-800"
            >
            + New automation flow
            </Link>
        </Header>
        <main className="mx-auto sm:px-6 lg:px-8 py-5">

            <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">

                {automationflows.map((client:any) => (
                    <Link  key={client.id} to={"/automation-flows/"+client.shortid}>
                    <li className="overflow-hidden rounded-xl border border-gray-600 bg-gray-800">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-900 p-6">
                        {/*<img
                        src={client.imageUrl}
                        alt={client.name}
                        className="h-12 w-12 flex-none rounded-lg bg-gray-900 object-cover ring-1 ring-gray-900/10"
                        />*/}
                        <div className="h-12 w-12 flex-none rounded-lg bg-blue-950 object-cover ring-1 ring-gray-900/10 flex items-center justify-center">
                            <PlayIcon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                        </div>

                        <div className="text-md font-bold leading-6 text-gray-400">{client.name}</div>
                        {/*<Menu as="div" className="relative ml-auto">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Open options</span>
                            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-gray-700 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                <Link to={"/automation-flows/"+client.shortid}
                                    className={classNames(
                                    active ? 'bg-gray-800' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-300'
                                    )}
                                >
                                    View<span className="sr-only">, {client.name}</span>
                                </Link>
                                )}
                            </Menu.Item>
                            {client.status !== "Active" ? 
                            
                            <Menu.Item>
                                {({ active }) => (
                                <Link to="#"
                                    className={classNames(
                                    active ? 'bg-gray-800' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-300'
                                    )}
                                >
                                    Activate<span className="sr-only">, {client.name}</span>
                                </Link>
                                )}
                            </Menu.Item> : 
                            
                            <Menu.Item>
                                {({ active }) => (
                                <Link to="#"
                                    className={classNames(
                                    active ? 'bg-gray-800' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-300'
                                    )}
                                >
                                    Disable<span className="sr-only">, {client.name}</span>
                                </Link>
                                )}
                            </Menu.Item>}
                            </Menu.Items>
                        </Transition>
                        </Menu>*/}
                    </div>
                    <dl className="-my-3 divide-y bg-gray-800 divide-gray-700 px-6 py-4 text-sm leading-6">
                        {/*<div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-400">Last run</dt>
                        <dd className="text-gray-400">
                            <time dateTime={client.dateTime}>{client.created_at}</time>
                        </dd>
                        </div>*/}
                        <div className="flex justify-between gap-x-4 py-3">
                        <div className='flex gap-x-2'>
                            <dt className="text-gray-400">Steps</dt>
                            <div className="font-medium text-gray-400">{client.steps}</div>
                        </div>
                        <dd className="flex items-start gap-x-2">
                            <div
                            className={classNames(
                                statuses[client.status],
                                'rounded-md ml-auto py-1 px-2 text-xs font-medium ring-1 ring-inset'
                            )}
                            >
                            {client.status}
                            </div>
                        </dd>
                        </div>
                    </dl>
                    </li>
                    </Link>
                ))}
            </ul>

        </main>
    </>
    )
}
