import {axios} from "../axios";
import { supabase } from "../supabase";
import qs from "qs"


export enum SENDTYPES {
singleEmail="singleEmail",
customerSegment="customerSegment",
all="all"
}

export enum SIDEBARNAVTYPE {
default,
html
}


// update send type and update counter for how many it will be sent to
export let getSendTypeCountFunction = async (type:SENDTYPES,teamId:string,state:any,e?:any) => {

    if (type === SENDTYPES.all){

        // return count of all subscribers
        const {data,error} = await supabase.from("subscribers").select("*").filter("team_id","eq",teamId)
        if (error){
            alert("An error occoured when trying to find all your subscribers")
            return;
        } else {
            state(data.length)
        }
        
    } else if (type === SENDTYPES.customerSegment){
      state(0)
      if (e !== undefined && e.target.value.split(" ").shift().includes("#")){ // check format is #ID NAME
        let id = e.target.value.split(" ").shift().replaceAll("#","")
        // return count of all subscribers
        const {data,error} = await supabase.from("customer_segments").select("subscriber_count").match({id: id, team_id:teamId}).single()
        if (error!=null){
            alert("An error occoured when trying to find all your subscribers")
            return;
        } else {
            state(data.subscriber_count)
        }
      }
        // return count of customer segment
    } else if (type === SENDTYPES.singleEmail){
        state(1)
    }

}

// generate the email model
export async function generateContext(e:any,intent:string,hexColor:string,domain:string){
  e.preventDefault() 
  

  if (intent.split("").length <= 35){
    alert("Please describe the intent in a few more words")
    return false;
  }

  if (intent.split(" ").length <= 5){
    alert("Please describe the intent in a few more words")
    return false;
  }

  // set spinner for button and disable button

  let res = await axios.post(process.env.REACT_APP_API+"/v1/emails/intent",qs.stringify({intent:intent,hex_color:hexColor,domain:domain}))

  if (!res.data.chatCompletion.choices[0].message.content){
      alert("An error occoured")
    }

    let email = res.data.chatCompletion.choices[0].message.content.replaceAll("```html","")
    email = email.replaceAll("```","")
    return (email)
}
  
// send or save the email model
export async function updateEmail({
  id,
  campaignId,
  campaignName,
  intent,
  singleEmailReceiver,
  customerSegment,
  sampleGeneratedEmailModel,
  teamId,
  domain,
  teamName,
  sendEmailNow,
  sendType,
  setSendEmailNowLoader, // This needs to be a state updater function passed in
}:{
  id:string,
  campaignId:string,
  campaignName:string,
  intent:string,
  singleEmailReceiver:string,
  customerSegment:string,
  sampleGeneratedEmailModel:string,
  teamId:string,
  domain:string,
  teamName:string,
  sendEmailNow:boolean,
  sendType:string,
  setSendEmailNowLoader:any,
}){

  if (!campaignName){
    alert("Missing field campaign name")
    return;
  }
  if (!intent){
    alert("Missing field context intent")
    return;
  }

  if ((singleEmailReceiver==="" && sendType===SENDTYPES.singleEmail && sendEmailNow)||(customerSegment==="" && sendType===SENDTYPES.customerSegment && sendEmailNow)){
    alert("Missing receivers")
    return;
  }
  if (!sampleGeneratedEmailModel){
    alert("Couldn't find any generated email models")
    return;
  }

  if (!teamId){
    alert("Couldn't find team ID")
    return;
  }

  if (!domain){
      alert("Couldn't find domain")
      return;
  }
  if (!teamName){
      alert("Couldn't find team name")
      return;
  }

  setSendEmailNowLoader(true)

  // update email
  await supabase.from("email_models").update({
      name: campaignName,
      send_now: sendEmailNow,
      sent_to_single_email: singleEmailReceiver,
      customer_segment: customerSegment,
      email_model: sampleGeneratedEmailModel
  }).eq("id",campaignId)


  // email should be SENT NOW and saved
  if (sendEmailNow){
    let res = await sendEmailToUsers(sendType,domain,singleEmailReceiver,customerSegment,teamName,teamId,id)
    if (res){
      return true;
    }
  } else {
    return true;
  }
  

}
// newcampaign save the email model
export async function createEmail({
  campaignName,
  intent,
  sampleGeneratedEmailModel,
  teamId,
  sendEmailNow,
  singleEmailReceiver,
  sendType,
  customerSegment,
  teamName,
  domain,
  setSendEmailNowLoader,
}:{
  campaignName:string,
  intent:string,
  sampleGeneratedEmailModel:string,
  teamId:string,
  sendEmailNow:boolean,
  singleEmailReceiver:string,
  sendType:any,
  customerSegment:string,
  teamName:string,
  domain:string,
  setSendEmailNowLoader:any,
}){


  if (!campaignName){
    alert("Missing field campaign name")
    return;
  }
  if (!intent){
    alert("Missing field context intent")
    return;
  }
  
  if ((singleEmailReceiver==="" && sendType===SENDTYPES.singleEmail && sendEmailNow)||(customerSegment==="" && sendType===SENDTYPES.customerSegment && sendEmailNow)){
    alert("Missing receivers")
    return;
  } else if ((singleEmailReceiver!=="" && sendType===SENDTYPES.singleEmail && sendEmailNow)){
    // check email exists among subscribers
    let subscribers = await supabase.from("subscribers").select("*").filter("team_id","eq",teamId).filter("email","eq",singleEmailReceiver);
    if (subscribers.data!==null && subscribers.data.length < 1){
      alert("Subscriber must be in your subscriber list to personalize the email");
      return false;
    }
  }

  if (!sampleGeneratedEmailModel){
    alert("Couldn't find any generated email models")
    return;
  }

  if (!teamId){
    alert("Couldn't find team ID")
    return;
  }

  if (!domain){
    alert("Couldn't find domain")
    return;
  }

  if (!teamName){
    alert("Couldn't find team name")
    return;
  }

  setSendEmailNowLoader(true)
  // save email
  const {data} = await supabase.from("email_models").insert({
    name: campaignName,
    intent: intent,
    email_model:sampleGeneratedEmailModel,
    send_now: sendEmailNow,
    sent_to_single_email: singleEmailReceiver,
    send_type: sendType,
    customer_segment: customerSegment,
    team_id: teamId
  }).select()

  // email should be SENT NOW and saved
  if (sendEmailNow){
      if (data == null){
          alert("Error while sending email. Email has not been sent")
          return;
      }
      
      const id = data[0].id

      let res = await sendEmailToUsers(sendType,domain,singleEmailReceiver,customerSegment,teamName,teamId,id)
      if (res){
        return true;
      }
    }
  


  return true;

}

export async function sendEmailToUsers(sendType:any,domain:string,singleEmailReceiver:string,customersegment:string,teamName:string,teamId:string,id:string){
    if (sendType===SENDTYPES.singleEmail){ 

        axios.post(process.env.REACT_APP_API+"/v1/send/single",qs.stringify({
            from: "hello@"+domain,
            name:teamName,
            to: singleEmailReceiver,
            emailModel: id,
            teamId:teamId
        })).catch((e) => {
          if (e.response.data.message){
            alert(e.response.data.message)
          } else if (e.message){
            alert(e.message)
          } else {
            alert(e)
          }
        })
        return true;

      } else if (sendType===SENDTYPES.customerSegment){
        console.log(singleEmailReceiver); // Reference by form input's `name` tag

        axios.post(process.env.REACT_APP_API+"/v1/send/customersegment",qs.stringify({
          from: "hello@"+domain,
          name:teamName,
          customersegment: customersegment,
          emailmodel: id,
          teamid:teamId
      })).catch((e) => {
        if (e.response.data.message){
          alert(e.response.data.message)
        } else if (e.message){
          alert(e.message)
        } else {
          alert(e)
        }
      })
      return true;
      } else if (sendType===SENDTYPES.all){

        axios.post(process.env.REACT_APP_API+"/v1/send/all",qs.stringify({
            from: "hello@"+domain,
            name:teamName,
            emailModel:id,
            teamId:teamId
        })).catch((e) => {
          if (e.response.data.message){
            alert(e.response.data.message)
          } else if (e.message){
            alert(e.message)
          } else {
            alert(e)
          }
         })
        return true;

      }
}