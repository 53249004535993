import { useEffect } from "react";
import { Handle, Position } from "reactflow";

export const TimeDelayNodeComponent = ({ id, data, setNodes }:any) => {

  useEffect(() => {

    // initialize new value to be able to check if nodes with values are empty
    setNodes((nds:any) =>
      nds.map((node:any) =>
        !node.data.value && node.id === id ? { ...node, data: { ...node.data, value:"" } } : node
      )
    );
  },[])


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      // Update the node's data with the selected campaign
      setNodes((nds:any) =>
        nds.map((node:any) =>
          node.id === id ? { ...node, data: { ...node.data, value:value } } : node
        )
      );
    };

  return (
    <div className="bg-transparent border border-gray-900 p-3 w-28 overflow-hidden text-center rounded-sm">
      {/* Entry handle */}
      <Handle type="target" position={Position.Top} style={{ borderRadius: 100 }} />
      <div className="text-xs">
        {data.label}
      </div>
      <div className="flex justify-center items-center">
        <input onChange={handleChange} value={data.value ? data.value : ""}  type="number" className="text-xs rounded-sm mt-2 h-6 bg-transparent border-gray-800 text-white w-full"/>
        <p className="text-xs ml-1">H</p>
      </div>
      {/* Exit handles */}
      <Handle type="source" position={Position.Bottom} id="a" style={{ top: '95%', left:'50%', borderRadius: 100, background: '#555' }} />
    </div>
  );
};