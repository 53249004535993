import { Fragment, useContext, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'


import Header from '../../../components/layout/Header'
import { Link } from 'react-router-dom'
import { TeamContext } from '../../../helpers/TeamContext'
import { supabase } from '../../../helpers/supabase'


const statuses :any = {
  all: 'text-green-700 bg-transparent ring-green-600/50',
  customersegment: 'text-gray-600 bg-transparent ring-gray-500/50',
  singleEmail: 'text-yellow-800 bg-transparent ring-yellow-600/50',
}
const projects :any  = [
  {
    id: 1,
    name: 'New feature launch',
    href: '/emails/123',
    status: 'Complete',
    createdBy: 'Lukas M. A. Guldstøv',
    createdAt: 'March 17, 2023',
    dueDateTime: '2023-03-17T00:00Z',
  },
  {
    id: 2,
    name: 'New benefits plan',
    href: '/emails/1234',
    status: 'In progress',
    createdBy: 'Lukas M. A. Guldstøv',
    createdAt: 'May 5, 2023',
    dueDateTime: '2023-05-05T00:00Z',
  },
  {
    id: 5,
    name: 'New product sales',
    href: '/emails/1243',
    status: 'Archived',
    createdBy: 'Courtney Henry',
    createdAt: 'June 10, 2023',
    dueDateTime: '2023-06-10T00:00Z',
  },
]

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
  }
  

export default function EmailCampaigns({props}:any) {

  const {teamId} = useContext(TeamContext)
  const [campaigns,setCampaigns]:any = useState([]);

  useEffect(() => {
    async function getCampaigns(){

      const {data,error}= await supabase.from("email_models").select("*").filter("team_id","eq",teamId).order("created_at", {ascending:false});
      if (error){
        alert(error);
      }

      setCampaigns(data)

    }

    getCampaigns()

  },[])
  
  return (
    <>
            <Header title="Email campaigns">
                <Link to="/emails/new"
                    className="w-30 text-center rounded-md bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-slate-800"
                >
                + New campaign
                </Link>
            </Header>

             <main className="mx-auto sm:px-6 lg:px-8 py-5">
                <ul  className=" divide-y divide-gray-800">
                {campaigns.length >= 1 && campaigns.map((project:any) => (
                    <li key={project.id} className="flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0">
                        <div className="flex items-start gap-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-200">{project.name}</p>
                        {project.send_now && <p
                            className={classNames(
                            statuses[project.send_type],
                            'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                            )}
                        >
                          {project.send_type}
                            
                        </p>}
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                            Created at {project.created_at.split("T")[0]}
                        </p>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                            <circle cx={1} cy={1} r={1} />
                        </svg>
                        <p className="truncate">Created by you</p>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                            <circle cx={1} cy={1} r={1} />
                        </svg>
                        <p className="truncate">{project.send_now?"Sent":"Not sent yet"}</p>
                        
                        </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                        <Link to={"/emails/"+(project.id)}
                        className="hidden rounded-md bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-slate-800 sm:block"
                        >
                        View campaign<span className="sr-only">, {project.name}</span>
                        </Link>
                        {/*
                        <Menu as="div" className="relative flex-none">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-700">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-gray-700 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                <a
                                    href="#"
                                    className={classNames(
                                    active ? 'bg-gray-800' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-300'
                                    )}
                                >
                                    Edit<span className="sr-only">, {project.name}</span>
                                </Link>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                <Link to="#"
                                    className={classNames(
                                    active ? 'bg-gray-800' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-300'
                                    )}
                                >
                                    Delete<span className="sr-only">, {project.name}</span>
                                </Link>
                                )}
                            </Menu.Item>
                            </Menu.Items>
                        </Transition>
                        </Menu>*/}
                    </div>
                    </li>
                ))}
                </ul>    
            </main>
      
    </>
  )
}
