
import { useContext, useEffect, useState } from 'react'
import Header from '../../../components/layout/Header'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { supabase } from '../../../helpers/supabase'
import { TeamContext } from '../../../helpers/TeamContext'
import { TagSelectSnippet } from '../../../components/input/TagSelectSnippet'
import { InterestsInputSnippet } from '../../../components/input/InterestsInputSnippet'
import { InputWithTitle } from '../../../components/input/InputWithTitle'
import { TitleFieldWrapper } from '../../../components/input/TitleFieldWrapper'
import { SelectCountry } from '../../../components/input/SelectCountry'
import { SelectLanguage } from '../../../components/input/SelectLanguage'
import { ButtonActions } from '../../../components/layout/ButtonActions'
import ButtonDelete from '../../../components/input/ButtonDelete'

 


 

 

export default function NewSubscribers() {

    const navigate = useNavigate();

    const {team,teamId} = useContext(TeamContext)
    const {id} = useParams()

    // add states
    const [email,setEmail] = useState<string>("")
    const [firstName,setFirstName] = useState<string>("")
    const [lastName,setLastName] = useState<string>("")
    const [phone,setPhone] = useState<string>("")
    const [birthday,setBirthday] = useState<string>("")
    const [country,setCountry] = useState<string>("")
    const [language,setLanguage] = useState<string>("")
    const [city,setCity] = useState<string>("")
    const [address,setAddress] = useState<string>("")
    const [region,setRegion] = useState<string>("")
    const [zipCode,setZipCode] = useState<string|null>("")
    const [memberRating,setMemberRating] = useState<string>("")

    const [hasLoaded, setHasLoaded] : any = useState(false)

    // tags and tag management
    const [tags, setTags] : any = useState(null);
    const [interests, setInterests] : any = useState([]);


    const secondaryNavigation : any = [/*
      { name: 'Manage settings', href: `/subscribers/manage/${id}/edit`, current: true },
      { name: 'View interactions', href: `/subscribers/manage/${id}/interactions`, current: false },
    */]
    

    useEffect(() => {
      async function subscriber(){
        let {data,error} = await supabase.from("subscribers").select("*").match({"id":id,"team_id":teamId}).single()
 
        setEmail(data.email ?? "");
        setFirstName(data.first_name ?? "");
        setLastName(data.last_name ?? "");
        setPhone(data.phone ?? "");
        setBirthday(data.birthday ?? "");
        setCountry(data.country ?? "");
        setLanguage(data.language ?? "");
        setCity(data.city ?? "");
        setAddress(data.address ?? "");
        setRegion(data.region ?? "");
        setZipCode(data.zip_code ?? ""); // Note: Typo in 'setZioCode' should be 'setZipCode'
        setMemberRating(data.member_rating ?? "");
        // Assuming tags and interests are arrays of objects
        setTags(data.tags?.tags ?? []);
        setInterests(data.interests?.interests ?? []);

        setHasLoaded(true)

      }
      subscriber()
    },[])
  
 
    // submit function
    let submit = async (e:any) => {
      e.preventDefault();

      const form = e.target; // This is the form element
      const email = form.elements.namedItem('email').value.toLowerCase();
      const first_name = form.elements.namedItem('first_name').value;
      const last_name = form.elements.namedItem('last_name').value;
      const phone = form.elements.namedItem('phone').value;
      const birthday = form.elements.namedItem('birthday').value;
      const country = form.elements.namedItem('country').value;
      const language = form.elements.namedItem('language').value;
      const city = form.elements.namedItem('city').value;
      const address = form.elements.namedItem('address').value;
      const region = form.elements.namedItem('region').value;
      const zip_code = form.elements.namedItem('zip_code').value;
      

      // tags and interests from state objects
      let interestsArr = interests.length >= 1 ? interests.map((interest:any) => interests!==null && interest.value) : []
      const interestsC = {"interests": interestsArr}

   
      if (!email){
        alert("Email cannot be empty")
        return;
      }

      const teamId = JSON.parse(team)[0].team_id

      if (!teamId){
        alert("Couldn't find team id")
        return;
      }

      // check if email exist in subscriber list
      const {data,error} = await supabase.from("subscribers").select("*").filter("team_id","eq",teamId).filter("email","eq",email).single()

      if (error){
        alert(error);
        return;
      }
 
      if (data != null && data.length >= 1 && data.email !== email){
        alert("Email already exists among another subscribers");
        return;
      }

      const tagsC = tags.length >= 1 && (tags.map((tag:any) => tag != null && tag.value));

      // check if tag exist - if not insert
      let tagData = []
      for (let i = 0; tagsC.length > i; i++){
        if (tagsC[i]===""){
          break;
        }
 
        const { data } = await supabase
        .from('customer_tags')
        .select("*")
        .filter("team_id","eq",teamId)
        .filter("tag","eq",tagsC[i])

        if (data?.length === 0){

          const { data } = await supabase
          .from('customer_tags')
          .insert({
            team_id: teamId,
            tag: tagsC[i].toLowerCase()
          }).select()
          
          if (data){
            tagData.push(data[0].id)
          }

        } else {
          if (data){
            tagData.push(data[0].id)
          }
        }
      }
      // get tag id to insert as reference
      const tagsId = {"tags":tagData}

      // insert subscriber
      await supabase.from("subscribers").update({
        email: email,
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        birthday: birthday,
        tags: tagsId,
        interests: interestsC,
        language: language,
        country: country,
        city: city,
        address: address,
        region: region,
        zip_code: zip_code
      }).match({team_id: teamId, id: id}).then((res) => {
        navigate("/subscribers",{state:{newsubscriber:true}})
      })


    }


    if (!hasLoaded ){
      return <p>Loading</p>
    }

  return (
    <>
    <Header title="Edit subscriber">
      <ButtonDelete 
      className={"ml-auto"}
      onClick={async () => {
        try {
          await supabase.from("subscribers").delete().match({"id":id,"team_id":teamId})
          navigate("/subscribers")
        } catch (e){
          alert(JSON.stringify(e))
        }
      }}/>
    </Header>
    {/* Secondary navigation */}
    {secondaryNavigation.length >= 1 && <nav className="flex overflow-x-auto py-4">
        <ul
        className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
        >
        {secondaryNavigation.map((item:any) => (
            <li key={item.name}>
                <Link to={item.href} className={item.current ? 'text-indigo-600' : ''}>
                {item.name}
                </Link>
            </li>
        ))}
        </ul>
    </nav>}

    <main className=" sm:px-6 lg:px-8">


    <form onSubmit={submit}>


    <div className="border-b border-white/10 pb-12 mt-7">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 w-3/5 ">

 

            <div className="sm:col-span-4">
              <InputWithTitle value={email} onChange={(e:any) => setEmail(e.target.value)} name="email" title="Email address *"/>
            </div>

            <div className="sm:col-start-1 sm:col-span-2">
              <InputWithTitle value={firstName} onChange={(e:any) => setFirstName(e.target.value)} name="first_name" title="First Name"/>
            </div>

            <div className="sm:col-span-2">
              <InputWithTitle value={lastName} onChange={(e:any) => setLastName(e.target.value)} name="last_name" title="Last Name"/>
            </div>


            <div className="col-span-4">
              <label htmlFor="phone" className="block text-sm font-medium leading-6 text-white">
                Phone
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={(e:any) => setPhone(e.target.value)}
                  autoComplete="phone"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-4">
              <TitleFieldWrapper name="birthday" title='Birthday'>
                <input
                    type="date"
                    name="birthday"
                    id="birthday"
                    autoComplete="birthday"
                    value={birthday ? new Date(birthday).toISOString().slice(0, 10) : ''}
                    onChange={(e:any) => setBirthday(e.target.value)}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
              </TitleFieldWrapper>
            </div>


            <div className="sm:col-span-4">
              <TitleFieldWrapper name="country" title="Country">
                <SelectCountry defaultValue={country} onChange={(e:any) => setCountry(e.target.value)}/>
              </TitleFieldWrapper>
            </div>


            <div className="sm:col-span-4">
              <TitleFieldWrapper name="language" title="Language">
                <SelectLanguage defaultValue={language} onChange={(e:any) => setLanguage(e.target.value)}/>
              </TitleFieldWrapper>
            </div>

            <div className="sm:col-span-4 sm:col-start-1">
              <InputWithTitle name="city" title="City" value={city} onChange={(e:any) => setCity(e.target.value)}/>
            </div>

            <div className="sm:col-span-4 sm:col-start-1">
              <InputWithTitle name="address" title="Address" value={address} onChange={(e:any) => setAddress(e.target.value)}/>
            </div>

            <div className="sm:col-span-4 sm:col-start-1">
              <InputWithTitle name="region" title="Region/State/Province" value={region} onChange={(e:any) => setRegion(e.target.value)}/>
            </div>

            <div className="col-span-4">
              <InputWithTitle name="zip_code" title="Zip code" value={zipCode} onChange={(e:any) => setZipCode(e.target.value)}/>
            </div>


            <div className="sm:col-span-4">
              <TagSelectSnippet valueArray={tags} setTagsValue={setTags}/>
            </div>

            <div className="sm:col-span-4">
              <InterestsInputSnippet value={interests} setInterestsValue={setInterests}/>
            </div>
            
          </div>
        </div>


        <ButtonActions cancelLink="/subscribers"/>

    </form>

    </main>
    </>
  )
}
