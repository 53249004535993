import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { AuthContext } from "./AuthContext";
import { supabase } from './supabase';

// Define the shape of your context state
interface TeamContextState {
  team: any | null | undefined;
  teamId: any | null | undefined;
  hexColor: any | null | undefined;
  teamName: any | null | undefined;
  domain: any | null | undefined;
  apiKey: any | null | undefined;
  setTeam: (token: any | null) => void;
}

// Create the context with a default value
export const TeamContext = createContext<TeamContextState>({
  team: null,
  teamId: null,
  hexColor: null,
  teamName: null,
  domain: null,
  apiKey: null,
  setTeam: () => {},
});

// Typing for the props of AuthProvider
interface TeamProviderProps {
  children: ReactNode; // This will accept any valid React node
}

// Create a provider component
export const TeamProvider: React.FC<TeamProviderProps> = ({ children }) => {

  const {token} = useContext(AuthContext);// This should be your actual logic to determine if the user is logged in
  const [team, setTeam] = useState<string | null | undefined>(undefined);
  const [teamId, setTeamId] = useState<string | null | undefined>(undefined);
  const [teamName, setTeamName] = useState<string | null | undefined>(undefined);
  const [domain, setDomain] = useState<string | null | undefined>(undefined);
  const [hexColor, setHexColor] = useState<string | null | undefined>(undefined);
  const [apiKey, setApiKey] = useState<string | null | undefined>(undefined);

  useEffect(() => {
    // Sync with local storage upon initial load
   // let teams :any = localStorage.getItem('activeTeam')
    async function getTeams(){
 /*     let size = 0
      try {
        size = JSON.parse(teams).length
      } catch (e) {}
*/
      try {
        if (token){

          let {data: {user}} = await supabase.auth.getUser()
          let {data} = await supabase.from("teams").select("*").filter("owner","eq",user?.id)

          setTeam(JSON.stringify(data));
          data && setTeamId(data[0].team_id);
          data && setHexColor(data[0].hex_color);
          data && setTeamName(data[0].team_name);
          data && setDomain(data[0].domain);
          data && setApiKey(data[0].api_key);

        }
      } catch (e){}
    }
    getTeams()
  }, []);

  useEffect(() => {
    // Update local storage whenever the token changes
    if (team) {
      localStorage.setItem('activeTeam', team);
    } else {
      localStorage.removeItem('activeTeam');
    }
  }, [team]);

  return (
    <TeamContext.Provider value={{ team, teamId, teamName, hexColor, domain,apiKey, setTeam }}>
      {children}
    </TeamContext.Provider>
  );
};
