/* eslint-disable no-restricted-globals */
import { Link, useNavigate, useParams } from 'react-router-dom'

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon } from '@heroicons/react/24/outline'
import Header from '../../../components/layout/Header'
import { useContext, useEffect, useState } from 'react'
import { supabase } from '../../../helpers/supabase'
import { TeamContext } from '../../../helpers/TeamContext'
import { UserLocalTime } from '../../../helpers/TimeFormat'
import ButtonDelete from '../../../components/input/ButtonDelete'
 

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}
  

export default function ViewCampaigns() {

  
  const {id} = useParams();
  const {teamId} = useContext(TeamContext)

  const navigate = useNavigate();

 // formdata
 const [campaignName,setCampaignName] = useState<string>("");

 const [emailStatistics, setEmailStatistics] : any = useState([])

 const [totalReceivers,setTotalReceivers] : any = useState(".")
 const [openRate,setOpenRate] : any = useState(".")
 const [clickRate,setClickRate] : any = useState(".")

 let stats = [
  { id: 1, name: 'Total receivers', stat: totalReceivers, icon: UsersIcon, change: '122', changeType: 'increase' },
  { id: 2, name: 'Avg. Open Rate', stat: openRate, icon: EnvelopeOpenIcon, change: '5.4%', changeType: 'increase' },
  { id: 3, name: 'Avg. Click Rate', stat: clickRate, icon: CursorArrowRaysIcon, change: '5.2%', changeType: 'increase' },
]



  useEffect(() => {
    async function getEmail(){
        const model = await supabase.from("email_models").select("*").filter("team_id","eq",teamId).filter("id","eq",id)
        const emailsGeneratedForUser = await supabase.from("email_generated_for_users").select(`
        id, 
        subscriber,
        email_model,
        delivered,
        opened,
        clicked,
        created_at,
        team_id,
        subscriber ( first_name, last_name, email )
      `).filter("team_id","eq",teamId).filter("email_model","eq",id).order("created_at",{ascending:false})

        if (model.error){
            alert("An error occoured");
        } else {

            const email = model.data[0]
            let emailsGenerated:any = emailsGeneratedForUser.data
            
            let emailsOpened : any = 0
            let emailsClicked : any = 0
            let emailsOpenrate : any = 0

            if (emailsGenerated !== null){
              emailsOpened = emailsGenerated.filter((e:any) => e.opened !== null)
              emailsClicked = emailsGenerated.filter((e:any) => e.clicked !== null)
              emailsClicked = ((emailsClicked.length / emailsGenerated.length)*100).toFixed(2)
              emailsOpenrate = ((emailsOpened.length / emailsGenerated.length)*100).toFixed(2)
            } else {
              emailsGenerated = []
            }

            setCampaignName(email.name)
            setEmailStatistics(emailsGenerated)
            setTotalReceivers(emailsGenerated?.length)
            setOpenRate((!isNaN(emailsOpenrate)?emailsOpenrate:0)+"%")
            setClickRate((!isNaN(emailsClicked)?emailsClicked:0)+"%")


        }

    }
    getEmail()
  },[])


  if (id === undefined){
    return <p>Error</p>
  }


  return (
    <>

      <Header title={campaignName}>
        <Link to={`/emails/${id}/send`}
            className="ml-auto rounded-md bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-slate-800 sm:block">
            Send campaign
          </Link>
        <ButtonDelete onClick={async () => {
          if (confirm("do you want to delete this email model? If you're using this email in your automation flows the flow will return an error.")){

              try {
                let {data,error} = await supabase.from("email_models").delete().eq("id",id).eq("team_id",teamId)
                if (error){
                  alert(JSON.stringify(error))

                } else {

                  navigate("/emails")
                }
              } catch (e){
                alert(JSON.stringify(e))
              }
            }}
          }/>
          
      </Header>
      

      <div className="mx-8 my-5">
        <h3 className="text-base font-semibold leading-6 text-gray-500">Email campaign</h3>

        <dl className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map((item) => (
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-gray-950 px-4 pb-0 pt-5 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div className="absolute rounded-md bg-indigo-900 p-3">
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-0 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-300">{item.stat}</p>
                {/*<p
                  className={classNames(
                    item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                    'ml-2 flex items-baseline text-sm font-semibold'
                  )}
                >
                  {item.changeType === 'increase' ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                  )}

                  <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                  {item.change}
                </p>*/}
                {/*<div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <Link to="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                      View all<span className="sr-only"> {item.name} stats</span>
                    </Link>
                  </div>
                </div>*/}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <main className="mx-auto sm:px-6 lg:px-10">

        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-800">
                <thead>
                  <tr className="divide-x divide-gray-800">
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-500 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-500">
                      Email
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-500">
                      Delivered
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-500 sm:pr-0">
                      Opened
                    </th>{
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-500 sm:pr-0">
                      Clicked
                    </th>}
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-500 sm:pr-0">
                      Sent at
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-500 sm:pr-0">
                      
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800 bg-none">
                  {emailStatistics.map((person:any) => (
                    <tr key={person.email} className="divide-x divide-gray-800">
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-500 sm:pl-0">
                        {(person.subscriber.first_name !== null ? person.subscriber.first_name : "") + " " + (person.subscriber?.last_name!==null ? person.subscriber.last_name : "")}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.subscriber.email}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.delivered||1 ? "Yes" : "No"}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.opened ? "Yes" : "No"}</td>{
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.clicked ? "Yes" : "No"}</td>}
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{UserLocalTime(person.created_at)}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500 sm:pr-0">
                        <Link to={`${"/emails/"+id+"/"+person.id}`} className="text-blue-600 hover:text-blue-900">
                          View<span className="sr-only">, {person.name}</span>
                        </Link>  
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </main>
      
    </>
  )
}
