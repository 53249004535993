
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  ChartBarSquareIcon,
  XMarkIcon,
  ArrowRightStartOnRectangleIcon,
  HomeIcon,
  UsersIcon,
  FunnelIcon,
  EnvelopeIcon,
  TagIcon,
  PaperAirplaneIcon,
  MegaphoneIcon
} from '@heroicons/react/24/outline'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';


import Logo from "../../assets/icon.png"
import { supabase } from '../../helpers/supabase'
import { useNavigate } from 'react-router-dom'


let isCurrent = () => {
  return "/"+window.location.pathname.split("/")[1]
}

const navigationC = [
  { name: 'Dashboard', href: '/', icon: HomeIcon  },
  { name: 'Emails', href: '/emails', icon: EnvelopeIcon  },
  { name: 'Automation flows', href: '/automation-flows', icon: ChartBarSquareIcon  },
  { name: 'Customer segments', href: '/customer-segments', icon: FunnelIcon },
  { name: 'Customer tags', href: '/customer-tags', icon: TagIcon },
  { name: 'Subscribers', href: '/subscribers', icon: UsersIcon  },
  { name: 'Brand assets', href: '/assets/brand', icon: MegaphoneIcon  },
]
 
const otherC = [
  { name: 'Transactional API', href: '/transactional', icon: PaperAirplaneIcon  },
]
 


function classNames(...classes:string|string[]|any) {
  return classes.filter(Boolean).join(' ')
}

export default function LayoutWMenu({children,params}:any) : JSX.Element {

  const [sidebarOpen,setSidebarOpen] = useState<boolean>(false)


  return (
    <>
    
      <div>
        <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
        {/* Static sidebar for desktop */}
        <DesktopSidebar/>

        <div className="xl:pl-60">
          {/* Sticky search header */}
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-gray-900 px-4 shadow-sm sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-white xl:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              {/*<form className="flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-white focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                  />
                </div>
              </form>*/}
              <p className=" h-full w-full flex items-center justify-start border-0 bg-transparent py-0 pr-0 text-white focus:ring-0 sm:text-sm">
                Feedback or questions? Talk to one of the the founders: +45 60 88 91 43 | Email: admin@guldstoev.com
              </p>
            </div>
          </div>


          {/** imoport children */}
          {children}


        </div>
      </div>
    </>
  )
}



// the menu content (left menu)
function Menu(){

  const navigate = useNavigate()
  const [navigation, setNavigation] = useState(navigationC)
  const [other, setOther] = useState(otherC)
  const [current, setCurrent] = useState(isCurrent());

  const [domain,setDomain] = useState("");
  const [teams,setTeams] :any = useState([])
  
  useEffect(() => {
    async function supabaseCall(){
      try {

        const {data: {user}} = await supabase.auth.getUser()
        const {data}:any = await supabase.from("teams").select("*").filter("owner","eq",user?.id)
        setDomain(data[0].domain)
        setTeams([{ id: 1, name: data[0].team_name, href: ('/teams/'+data[0].id), initial: data[0].team_name.slice(0,1), current: true }])
        //      { id: 2, name: 'New team', href: '/create-team', initial: '+', current: false },  
      } catch (e){}
    }
    supabaseCall()
  },[])


  return (
    <nav className="flex flex-1 flex-col">
      <ul className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul className="-mx-2 space-y-1">
            {navigation.map((item:any) => (
              <li key={item.name}>
                <Link onClick={() => setCurrent(item.href)} to={item.href}
                  className={classNames(
                    current===item.href
                      ? 'bg-gray-800 text-white'
                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                  )}
                >
                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>

        <li>
          <div className="text-xs font-semibold leading-6 text-gray-400">Other</div>
          <ul>
          {other.map((item:any) => (
            <li key={item.name}>
            <Link onClick={() => setCurrent(item.href)} to={item.href}
              className={classNames(
                current===item.href
                  ? 'bg-gray-800 text-white'
                  : 'text-gray-400 hover:text-white hover:bg-gray-800',
                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
              )}
            >
              <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
              {item.name}
            </Link>
            </li>
          ))}
          </ul>
        </li>

        <li>
          <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
          <ul className="-mx-2 mt-2 space-y-1">
            {teams.map((team:any) => (
              <li key={team.name}>
                <Link to={team.href}
                  className={classNames(
                    team.current
                      ? 'bg-gray-800 text-white'
                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                  )}
                >
                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                    {team.initial}
                  </span>
                  <span className="truncate">{team.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li className="-mx-6 mt-auto flex">
          <div
            className="flex basis-full items-center gap-x-4 h-12 py-3 text-sm font-semibold leading-6 text-white "
          >
            
            <p className='pl-4'  aria-hidden="true">{domain}</p>
          </div>
          <button  
          className='w-16 flex justify-center items-center bg-gray-800'
          onClick={async () => {
              supabase.auth.signOut().then((res) => {

                localStorage.removeItem("teams")
                localStorage.removeItem("activeTeam")
                localStorage.removeItem("dkim")
                localStorage.removeItem("pendingDKIMVerification")
                navigate(0)
              })
          }}>
                <ArrowRightStartOnRectangleIcon className="h-6  text-gray-300"/>
          </button>
        </li>
      </ul>
    </nav>
  )
}


function MobileSidebar({sidebarOpen, setSidebarOpen}:{sidebarOpen:any, setSidebarOpen:any}){

  return (

    <Transition.Root show={sidebarOpen} as={Fragment}>
    <Dialog as="div" className="relative z-50 xl:hidden" onClose={setSidebarOpen}>
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-900/80" />
      </Transition.Child>

      <div className="fixed inset-0 flex">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
              <div className="flex h-16 shrink-0 items-center">
                <img
                  className="h-8 w-auto"
                  src={Logo}
                  alt="Your Company"
                />
              </div>

              {/** sidebar menu */}
              <Menu/>

            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>

  )
}

function DesktopSidebar(){

  return (
    <div className="bg-gray-900 hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-60 xl:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-12 w-auto"
                src={Logo}
                alt="Your Company"
              />
            </div>

            {/** sidebar menu */}
            <Menu/>

          </div>
        </div>
  )
}