import { TitleFieldWrapper } from "./TitleFieldWrapper"

export function InputWithTitle(
  {name,title,autoComplete,placeholder,value,onChange}:
  {name:string,title:string,autoComplete?:string,placeholder?:string,value?:any,onChange?:any}
){
  return (
    <TitleFieldWrapper name={name} title={title}>
      <input
        type="text"
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        placeholder={placeholder}
        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
      />
    </TitleFieldWrapper>
  )
}