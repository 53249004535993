
export function EventList({title,list}:{title:string,list:any[]}){
  return (
    <div className="mt-10">
        <p className="mb-3 font-bold">{title}</p>
        <div className="grid grid-cols-3 gap-3 text-xs">

        {list.map((e:any,idx:number) => {
            return (
              !e.hidden && 
              <div key={idx} className={`${e.className} text-center p-3 cursor-pointer`} onClick={e.onClick}>
                <p>{e.name}</p>
              </div>
              )
          })}
        </div>
      </div>
  )
}