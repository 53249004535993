


import {
    ChartBarSquareIcon,
    EnvelopeIcon,
    LifebuoyIcon,
    UsersIcon,
  } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { TeamContext } from '../../helpers/TeamContext'
import { useContext, useEffect, useState } from 'react'
import { supabase } from '../../helpers/supabase'
  
  const actions = [
    {
      title: 'New email model',
      href: '/emails/new',
      icon: EnvelopeIcon,
      description:"Create a new email model to start sending emails",
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-950',
    },
    {
      title: 'New automation flow',
      href: '/automation-flows/new',
      icon: ChartBarSquareIcon,
      description:"Create a new automation flow to create coherent journeys for your users",
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-950',
    },
    {
      title: 'New customer segment',
      href: '/customer-segments/new',
      icon: UsersIcon,
      description:"Create a new customer segment to better categorize your user groups",
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-950',
    },
    {
      title: 'Technical support',
      target:"_blank",
      href: 'mailto:admin@guldstoev.com',
      icon: LifebuoyIcon,
      description:"Support Call: +45 60 88 91 43 | Email: admin@guldstoev.com",
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-950',
    }
  ]

  /**
   * ,
    {
      title: 'Video guides',
      href: '#',
      icon: BanknotesIcon,
      iconForeground: 'text-yellow-700',
      iconBackground: 'bg-yellow-950',
    }
   */
   


/*
const secondaryNavigation = [
  { name: 'Overview', href: '#', current: true },
  { name: 'Activity', href: '#', current: false },
  { name: 'Settings', href: '#', current: false },
  { name: 'Collaborators', href: '#', current: false },
  { name: 'Notifications', href: '#', current: false },
]

const statuses : any = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
const activityItems = [
  {
    user: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    commit: '2d89f0c8',
    branch: 'main',
    status: 'Completed',
    duration: '25s',
    date: '45 minutes ago',
    dateTime: '2023-01-23T11:00',
  },
  // More items...
]
*/


function classNames(...classes:string|string[]|any) {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {

    const {teamId,team,domain} = useContext(TeamContext)


    const [emails,setEmails] = useState(0);
    const [subscribers,setSubscribers] = useState(0);
    const [openrate,setOpenrate] = useState(0);
    const [ctr,setCtr] = useState(0);

    let stats = [
        { name: 'Number of sent emails', value: emails },
        { name: 'Number of subscribers', value: subscribers, unit: '' },
        { name: 'Open rate', value: openrate, unit: '%' },
        { name: 'Click-through rate', value: ctr, unit: '%' },
      ]
    
  

    useEffect(() => {
        async function getStats(){
            const subscribers :any = await supabase.from("subscribers").select("*").filter("team_id","eq",teamId)
            const emailsSent :any = await supabase.from("email_generated_for_users").select("*").filter("team_id","eq",teamId)
             
            let emailsOpened = emailsSent.data.filter((e:any) => e.opened !== null)
            let emailsClicked = emailsSent.data.filter((e:any) => e.clicked !== null)
            let emailsSentVar = emailsSent.data.length

            emailsOpened = ((emailsOpened.length/emailsSentVar)*100).toFixed(2)
            emailsClicked = ((emailsClicked.length/emailsSentVar)*100).toFixed(2)
            

            setSubscribers(subscribers.data.length)
            setEmails(emailsSentVar)
            setOpenrate(!isNaN(emailsOpened)?emailsOpened:0)
            setCtr(!isNaN(emailsClicked)?emailsClicked:0)
            
        }
        getStats()

        
    },[])

    

    
  return (
     <main>
        <header>
            {/* Secondary navigation */}
            {/*<nav className="flex overflow-x-auto border-b border-white/10 py-4">
            <ul
                role="list"
                className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
            >
                {secondaryNavigation.map((item) => (
                <li key={item.name}>
                    <Link to={item.href} className={item.current ? 'text-indigo-400' : ''}>
                    {item.name}
                    </Link>
                </li>
                ))}
            </ul>
            </nav>*/}

            {/* Heading */}
            <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-700/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
            <div>
                <div className="flex items-center gap-x-3">
                <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                    <div className="h-2 w-2 rounded-full bg-current" />
                </div>
                <h1 className="flex gap-x-3 text-base leading-7">
                    <span className="font-semibold text-white">{JSON.parse(team)[0].team_name}</span>
                </h1>
                </div>
                <p className="mt-2 text-xs leading-6 text-gray-400">Sends emails from hello@{domain}</p>
            </div>
            <div className="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
                Production
            </div>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-1 bg-gray-700/10 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat, statIdx) => (
                <div
                key={stat.name}
                className={classNames(
                    statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                    'border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8'
                )}
                >
                <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
                <p className="mt-2 flex items-baseline gap-x-2">
                    <span className="text-4xl font-semibold tracking-tight text-white">{stat.value}</span>
                    {stat.unit ? <span className="text-sm text-gray-400">{stat.unit}</span> : null}
                </p>
                </div>
            ))}
            </div>
        </header>

        {/* Activity list */}
       {/* <div className="border-t border-white/10 pt-11">
            <h2 className="px-4 text-base font-semibold leading-7 text-white sm:px-6 lg:px-8">Latest activity</h2>
            <table className="mt-6 w-full whitespace-nowrap text-left">
            <colgroup>
                <col className="w-full sm:w-4/12" />
                <col className="lg:w-4/12" />
                <col className="lg:w-2/12" />
                <col className="lg:w-1/12" />
                <col className="lg:w-1/12" />
            </colgroup>
            <thead className="border-b border-white/10 text-sm leading-6 text-white">
                <tr>
                <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                    User
                </th>
                <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                    Commit
                </th>
                <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20">
                    Status
                </th>
                <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                    Duration
                </th>
                <th
                    scope="col"
                    className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8"
                >
                    Deployed at
                </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-white/5">
                {activityItems.map((item) => (
                <tr key={item.commit}>
                    <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                    <div className="flex items-center gap-x-4">
                        <img src={item.user.imageUrl} alt="" className="h-8 w-8 rounded-full bg-gray-800" />
                        <div className="truncate text-sm font-medium leading-6 text-white">{item.user.name}</div>
                    </div>
                    </td>
                    <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                    <div className="flex gap-x-3">
                        <div className="font-mono text-sm leading-6 text-gray-400">{item.commit}</div>
                        <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                        {item.branch}
                        </span>
                    </div>
                    </td>
                    <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                    <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                        <time className="text-gray-400 sm:hidden" dateTime={item.dateTime}>
                        {item.date}
                        </time>
                        <div className={classNames(statuses[item.status], 'flex-none rounded-full p-1')}>
                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                        </div>
                        <div className="hidden text-white sm:block">{item.status}</div>
                    </div>
                    </td>
                    <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-400 md:table-cell lg:pr-20">
                    {item.duration}
                    </td>
                    <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                    <time dateTime={item.dateTime}>{item.date}</time>
                    </td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>*/}

        <div className=" m-12 ">
        <h2 className="text-gray-300 font-bold text-xl mb-5">Quick actions</h2>
        <div className="divide-y divide-gray-700 overflow-hidden rounded-lg  shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
            {actions.map((action, actionIdx) => (
                <div
                key={action.title}
                className={classNames(
                    actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                    actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                    actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                    actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                    'group relative bg-gray-700/10 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                )}
                >
                <div>
                    <span
                    className={classNames(
                        action.iconBackground,
                        action.iconForeground,
                        'inline-flex rounded-lg p-3 ring-4 ring-gray-700'
                    )}
                    >
                    <action.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div className="mt-8">
                    <h3 className="text-base font-semibold leading-6 text-gray-300">
                    <Link to={action.href} target={action.target==="_blank" ? "_blank" : ""} rel={action.target==="_blank" ? "noreferrer" : ""} className="focus:outline-none">
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />
                        {action.title}
                    </Link>
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">
                    {action.description}
                    </p>
                </div>
                <span
                    className="pointer-events-none absolute right-6 top-6 text-gray-500 group-hover:text-gray-400"
                    aria-hidden="true"
                >
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
                </div>
            ))}
        </div>
        </div>

        </main>
  )
}
