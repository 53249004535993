
import { Link } from 'react-router-dom'
import Header from '../../../components/layout/Header'
import { TeamContext } from '../../../helpers/TeamContext'
import { useContext, useEffect, useState } from 'react'
import { supabase } from '../../../helpers/supabase'

 

export default function BrandAssets() {

  const {teamId} = useContext(TeamContext)
  const [assets,setAssets] : any = useState(null);
  const [reload,setReload] : any = useState(false);
  const dir = teamId+"/private/"

  useEffect(() => {    
    async function getAssets(){

      if (!teamId){
        alert("Can't find teamId")
        return;
      }


      const { data, error } = await supabase
      .storage
      .from('brand_assets')
      .list(dir, {
        limit: 100,
        offset: 0,
        sortBy: { column: 'created_at', order: 'desc' },
      })


      
      if (data !== null && data.length >= 1){

        const images = await supabase
        .storage
        .from('brand_assets')
        .createSignedUrls(data.map((e) => dir+e.name), 60)

        // add the signed URL's to the indexes of the array with the asset information (dir)
        if (images.data){
          console.log("new assets")

          let newArr : any = []
          data.map((e:any,idx) => newArr.push({...e,...images.data[idx]}))
          setAssets(newArr)
          setReload(false)

        } else {
          setAssets([])
        }
      } else if (error) {
        alert("Couldn't load assets")
      } else {
        setAssets([])
      }
    }
    getAssets()
    
  },[reload==true])


  let renderAssets = () => {
    return (
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 py-8 lg:grid-cols-4 xl:gap-x-8">
        {assets.map((asset:any) => {
          return (
            <li key={asset.id} className="h-72 overflow-hidden relative text-gray-500">
              <div className="group h-60 flex justify-center items-center aspect-h-7 aspect-w-10 w-full border border-indigo-600 overflow-hidden rounded-lg focus-within:ring-1 focus-within:ring-indigo-600  ">
                <img src={asset.signedUrl} alt="" className="pointer-events-none h-full w-full object-contain  group-hover:opacity-75" />
                <button type="button" className="absolute inset-0 focus:outline-none" onClick={() => window.open(asset.signedUrl,'_blank')}>
                  <span className="sr-only">View details for {asset.title}</span>
                </button>
              </div>
              <p className="pointer-events-none mt-2 block truncate text-md font-medium text-gray-600">{asset.name}</p>
              <p className="pointer-events-none block text-sm font-medium text-gray-500">{(asset.metadata.size / Math.pow(10,3)).toFixed(0)} KB</p>
            </li>
          )
        })}
        </ul>
    )
  }

  let submit = async (event:any) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0]
    if (file){
      const { data, error } = await supabase
      .storage
      .from('brand_assets')
      .upload(dir+file.name, file, {
        cacheControl: '3600',
        upsert: false
      })

      if (data){
        setReload(true)
      }

      if (error){
        alert(error.message)
      }
    }
    
  }

 
  if (assets === null){
    return <p className='text-white'>Loading</p>
  }

  return (
    <>

 

    <Header title="Brand assets">
      <form>
      <label htmlFor='file'>
        <div className="w-30 text-center rounded-md bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-slate-800">
        + New asset
        </div>
        <input type="file" id="file"  onChange={submit} className='hidden' accept="image/png, image/gif, image/jpeg, image/webp"/>
      </label>
      </form>
    </Header>

    <main className="mx-auto sm:px-6 lg:px-8">
    

      <div className="flow-root">

        {assets.length >= 1 ? 
        renderAssets()
        : <p className='text-white text-md'>No assets found</p>}
 
      </div>


    </main>
    </>
  )
}
