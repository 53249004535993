
import { Link } from 'react-router-dom'
import Header from '../../../components/layout/Header'
import { TeamContext } from '../../../helpers/TeamContext'
import { useContext, useEffect, useState } from 'react'
import { supabase } from '../../../helpers/supabase'

/*
Behavior
Demographic
Psychographic*/
const segments = [
    { id:123, tag: 'All users', description: "Some tag" },
    { id:123, tag: 'Sellers', description: "Some tag" },
    { id:123, tag: 'Buyers', description: "Some tag" },
    { id:123, tag: 'New buyers', description: "Some tag" },
    { id:123, tag: 'Buyers in Copenhagen', description: "Some tag" },
    // More people...
  ]




const segmentationType :any = {
    behavior: 'text-green-700 bg-transparent ring-green-600/50',
    demographic: 'text-purple-700 bg-transparent ring-purple-600/50',
    psychographic: 'text-blue-700 bg-transparent ring-blue-600/50',
    default: 'text-gray-600 bg-transparent ring-gray-500/50',
}

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
}


export default function CustomerTag() {

  const {team} = useContext(TeamContext)
  const [tags,setTags] : any = useState([]);

  useEffect(() => {    
    async function getTags(){
      const teamId = JSON.parse(team)[0].team_id

      if (!teamId){
        alert("Can't find teamId")
        return;
      }
      const {data} = await supabase.from("customer_tags").select("*").filter("team_id","eq",teamId).order("created_at",{ascending:false})

      if (data !== null && data.length >= 1){
       setTags(data)
      }
    }
    getTags()
    
  },[])


  const classNamesTH = "min-w-28 capitalize sticky top-16 z-10 border-b border-gray-800 bg-gray-950 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8";
  const classNamesTD = "border-gray-800 border-b whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6 lg:pl-8";

  return (
    <>
    <Header title="Customer tags">
      <Link to="/customer-tags/new"
          className="w-30 text-center rounded-md bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-slate-800"
      >
      + New tag
      </Link>
    </Header>

    <main className="mx-auto sm:px-6 lg:px-8">
    

      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th className={classNames(classNamesTH)}>
                    Tag name
                  </th>
                  <th className={classNames(classNamesTH)}>
                    Description
                  </th>
                   
                  <th className={classNames(classNamesTH)}>
                     
                  </th>
                   
                </tr>
              </thead>
              <tbody>
                {(tags!==null && tags.length >= 1) ? tags.map((tag:any, tagIdx:number) => (
                  <tr key={tagIdx}>
                  <td className={classNames(classNamesTD,` ml-auto`)}>
                    <span className='text-white bg-indigo-950 text-xs border border-indigo-600 p-1 m-1 rounded-md'>{tag.tag}</span>
                  </td>
                  
                  <td className={classNames(classNamesTD,`w-full ml-auto`)}>
                      {tag.description}
                    </td>
                    
                  <td className={classNames(classNamesTD,`ml-auto`)}>
                      <Link className='text-indigo-600' to={`/customer-tags/${tag.id}`}>Edit</Link>
                    </td>
                    
                    
                  </tr>
                )) : <p></p>}
              </tbody>
            </table>
          </div>
        </div>
      </div>


    </main>
    </>
  )
}
