import 'reactflow/dist/style.css'; 
import { useNavigate } from "react-router-dom";
 
import ReactFlowView from '../../../components/automationflows/ReactFlowView';
import { SubmitType } from '../../../helpers/EnumsAutomationFlow';
import { useGraphState } from '../../../components/layout/AutomationFlows/Nodes';
import { supabase } from '../../../helpers/supabase';
import { useContext } from 'react';
import { TeamContext } from '../../../helpers/TeamContext';

export default function NewAutomationFlow() {

  const navigate = useNavigate();
  const {teamId} = useContext(TeamContext)
  const { edges, nodes, setNodes,  setEdges } = useGraphState();


  function sortNodesByConnectionSequence(nodes:any, edges:any) {
    // Helper function to find the starting node
    const findStartingNode = () => {
      const targets = new Set(edges.map((edge:any) => edge.target));
      return edges.find((edge:any) => !targets.has(edge.source))?.source;
    };
  
    const startingNodeId = findStartingNode();
    if (!startingNodeId) {
      console.error("Starting node not found");
      return [];
    }
  
    // Create a mapping from source to target for easy lookup
    const sourceToTargetMap = new Map(edges.map((edge:any) => [edge.source, edge.target]));
  
    // Build the sorted list of node IDs by following the edge connections
    let currentNodeId = startingNodeId;
    const sortedNodeIds = [currentNodeId];
  
    while (currentNodeId) {
      const nextNodeId = sourceToTargetMap.get(currentNodeId);
      if (!nextNodeId) break; // Reached the end of the sequence
      sortedNodeIds.push(nextNodeId);
      currentNodeId = nextNodeId; // Move to the next node in the sequence
    }
  
    // Map the sorted IDs back to nodes, including nodes not in the edge sequence at the end
    const sortedNodes = sortedNodeIds.map(id => nodes.find((node:any) => node.id === id)).filter(node => node);
    const orphanNodes = nodes.filter((node:any) => !sortedNodeIds.includes(node.id));
    return [...sortedNodes, ...orphanNodes];
  }
  
   
  // submit the automation flow
  const submitAutomationFlow = (e:any) => {
    if (!e.submitType){
      alert("Missing submit type")
      return;
    }

    if (e.title===""){
      alert("Missing automation flow name")
      return;
    }

    let realNodes :any = nodes.slice(1)
 

    let submitType = e.submitType
    
    
    // each real node - 1 should equal edges otherwise there's "loose" items
    if (edges.length !== realNodes.length - 1){
      alert("Please delete nodes without any connections");
      return;
    }
 
    // check if any field with the data field value is empty 
    for (let i = 0; realNodes.length> i; i++){
      if (realNodes[i].data){
        if (realNodes[i]?.data?.value===""){
          alert("One or more of your nodes are missing input");
          return;
        }
      }
    }
 
    const sortedNodes = sortNodesByConnectionSequence(realNodes, edges);
    
    try {
      supabase.from("automation_flows").insert({
        team_id: teamId,
        nodes: realNodes,
        name: e.title,
        edges: edges,
        steps: realNodes.length,
        status: submitType
      }).select().single().then((res:any) => {
        sortedNodes.map(async (e,idx:number) => 
          await supabase.from("automation_flow_events").insert({
            team_id: teamId,
            automation_flow: res.data.id,
            order: idx,
            type: e.id,
            value: e.data?.value? e.data.value : null,
          })
      )

        navigate("/automation-flows")

      })
    } catch (e) {
      alert("Errors:"+ JSON.stringify(e));
    }
     
    
  }

  return (
    <>
      <ReactFlowView setFlowEdges={setEdges} setFlow={setNodes} submit={submitAutomationFlow}/>
      
    </>
  )
}
