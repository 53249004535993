import Logo from "../../assets/icon.png"
import Bg from "../../assets/forgottenpassword.webp"
import { useEffect, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {

  const navigate = useNavigate()

  const [email,setEmail] : any = useState("");
  const [password1,setPassword1] : any = useState("");
  const [password2,setPassword2] : any = useState("");
  const [isSubmit,setIsSubmit] : any = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1)); // since hash includes the '#' character
    const access_denied = params.get('error');
    
    if (access_denied) {
      setTimeout(() => { // timeout to give some time to set the token
        window.location.href = "/"
      },250)
    }

    async function a(){

      const {data} = await supabase.auth.getUser();
      setEmail(data.user?.email)

    }
    a()
  })

  let submit = async (e:any) => {
    e.preventDefault()
    if (!isSubmit){
        if (password1!==password2){
            alert("Passwords don't match")
            return;
        }

        localStorage.removeItem("resetpassword"); // if resetpassword has run remove it so users can sign in

        setIsSubmit(true)
        const { data, error } = await supabase.auth.updateUser({ password: password1 })

        navigate("/")

        if (data) alert("Password updated successfully!")
    }
  }



 

  return (
    <>
    
      <div className="flex min-h-[100vh] bg-gray-900 flex-1">
        <div className="flex basis-full lg:basis-1/2 lg:justify-center lg:items-center flex-col justify-center">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-14 w-auto"
                src={Logo}
                alt="Your Company"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-white">
                Reset password
              </h2>
              <p className="mt-2 text-sm font-bold leading-6 text-gray-300">
                Reset password for {email}
              </p>
            </div>

            <div className="mt-10">
              <div>
                <form onSubmit={submit} method="POST" className="space-y-6">
                  <div>
                    <label htmlFor="psw1" className="block text-sm font-medium leading-6 text-white">
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="psw1"
                        type="password"
                        autoComplete="new-password"
                        required
                        value={password1}
                        onChange={(e) => setPassword1(e.target.value)}
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                    </div>
                  </div>
  
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                      Password again
                    </label>
                    <div className="mt-2">
                      <input
                        id="psw2"
                        type="password"
                        autoComplete="new-password"
                        required
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                    </div>
                  </div>
  
  
                  <div>
                    <button
                      type="submit"
                      
                      className={`${isSubmit && "cursor-default"} flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                      
                    >
                      Confirm new password
                    </button>
                  </div>
                </form>
              </div>
  
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={Bg}
            alt=""
          />
        </div>
      </div>
    </>
  )
}
  