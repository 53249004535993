import axios from "axios"
import { supabase } from "./supabase"
 

// Create an axios instance
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(async config => {
  // Fetch the current session
  const { data, error } = await supabase.auth.getSession();

  if (data?.session) {
    // If the session exists, set the Authorization header
    config.headers.Authorization = `Bearer ${data.session.access_token}`;
  }

  return config;
}, error => {
  // Do something with request error
  return Promise.reject(error);
});

export {axiosInstance as axios}