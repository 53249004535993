
import { Link } from 'react-router-dom'
import Header from '../../../components/layout/Header'
import { useContext, useEffect, useState } from 'react'
import { supabase } from '../../../helpers/supabase'
import { TeamContext } from '../../../helpers/TeamContext'




const segmentationType :any = {
    behavior: 'text-green-700 bg-transparent ring-green-600/50',
    demographic: 'text-purple-700 bg-transparent ring-purple-600/50',
    psychographic: 'text-blue-700 bg-transparent ring-blue-600/50',
    default: 'text-gray-600 bg-transparent ring-gray-500/50',
}

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
}


export default function CustomerSegments() {

  const {teamId} = useContext(TeamContext)
  const [segments,setSegments] : any = useState([])

  useEffect(() => {
    async function getSegments(){
      let {data,error} = await supabase.from("customer_segments").select("*").eq("team_id",teamId).order("created_at",{ascending:false})

      data!== null && setSegments(data)
    }
    getSegments()
  },[])

  return (
    <>
    <Header title="Customer segments">
      <Link to="/customer-segments/new"
          className="w-30 text-center rounded-md bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-slate-800"
      >
      + New segment
      </Link>
    </Header>

    <main className="mx-auto sm:px-6 lg:px-8">
    

      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            
                <div className='flex sticky top-16 z-10 border-b border-gray-800 bg-gray-950 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'>
                  <div className="basis-3/12">Name</div>
                  <div className="basis-5/12">Description</div>
                  <div className="basis-2/12">Segment type</div>
                  <div className="basis-1/12">User count</div>
                  <div className="basis-1/12">
                    <span className="sr-only">Edit</span>
                  </div>
                </div>
                
                {segments.length >= 1 ? segments.map((segment:any, personIdx:number) => (
                  <div key={personIdx} className='flex border-b border-gray-800 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'>
                    <div className="basis-3/12">{segment.name}</div>
                    <div className="basis-5/12">{segment.description}</div>
                    <div className="basis-2/12">
                      <span className='rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'>{segment.segmentation_type}</span>
                    </div>
                    <div className="basis-1/12">{segment.subscriber_count}</div>
                    <div className="basis-1/12  text-right">
                      <Link to={`${"/customer-segments/"+segment.id}`} className="text-indigo-600 hover:text-indigo-800">
                        View<span className="sr-only">, {segment.name}</span>
                      </Link>
                    </div>
                  </div>
                ) ): <p className='text-white'>No customer segments found...</p>}
                
          </div>
        </div>
      </div>


    </main>
    </>
  )
}
