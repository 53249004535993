
import { Link } from 'react-router-dom'
import Header from '../../../components/layout/Header'
import { Suspense, useContext, useEffect, useState } from 'react'
import { TeamContext } from '../../../helpers/TeamContext'
import { supabase } from '../../../helpers/supabase'
import { UserLocalTime } from '../../../helpers/TimeFormat'
import { SubscriberList } from '../../../components/layout/SubscriberList'



const segmentationType :any = {
    behavior: 'text-green-700 bg-transparent ring-green-600/50',
    demographic: 'text-purple-700 bg-transparent ring-purple-600/50',
    psychographic: 'text-blue-700 bg-transparent ring-blue-600/50',
    default: 'text-gray-600 bg-transparent ring-gray-500/50',
}



export default function Subscribers() {

  const {teamId} = useContext(TeamContext)
  const [users,setUsers] : any = useState([]);
  const [tags,setTags] : any = useState([]);

  useEffect(() => {    
    async function getSubscribers(){

      if (!teamId){
        alert("Can't find teamId")
        return;
      }



      const tags = await supabase.from("customer_tags").select("*").filter("team_id","eq",teamId)
      const {data} = await supabase.from("subscribers").select("*").filter("team_id","eq",teamId).order("created_at",{ascending:false})

      if (data !== null && data.length >= 1){
        setTags(tags.data)
        setUsers(data)
      }
    }
    getSubscribers()
    
  },[])


  if (!tags){
    return <p>Loading</p>
  }
  
  return (
    <>
    <Header title={`Subscribers (${users.length})`}>
      <Link to="/subscribers/new"
          className="w-30 text-center rounded-md bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-slate-800"
      >
      + Add subscribers
      </Link>
    </Header>
    <Suspense fallback={<p className='bg-red-300'>Loading!</p>}>

    <main className="mx-auto sm:px-8 lg:px-8">
    

    <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-left">
            <SubscriberList subscribers={users}/>
          </div>
        </div>
      </div>


    </main>
    </Suspense>

    </>
  )
}
