import { Fragment, useCallback, useMemo, useState } from 'react'

import ReactFlow, { Controls, ReactFlowProps, addEdge, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import 'reactflow/dist/style.css';
import { useNavigate } from "react-router-dom";

 
import { Actions, Initializations, Rules, SubmitType, Triggers } from '../../helpers/EnumsAutomationFlow';
import { useGraphState, connectionLineStyle } from '../../components/layout/AutomationFlows/Nodes';
import { StartNodeComponent, newNode } from './nodes/StartNodeComponent';
import { IfElseNodeComponent } from './nodes/rules/IfElseNodeComponent';
import { EmailCampaignNodeComponent } from './nodes/actions/EmailCampaignNodeComponent';
import { TimeDelayNodeComponent } from './nodes/rules/TimeDelayNodeComponent';
import { EventList } from './layout/EventList';
import { SubmitButtonArea } from './layout/SubmitButtonArea';
import { TitleFieldWrapper } from '../input/TitleFieldWrapper';
 
  

export default function ReactFlowView({setFlowEdges, setFlow, submit}:any) {

  const navigate = useNavigate();

  const { nodes, edges,setNodes, setEdges } = useGraphState();
 
  const [title,setTitle] : any = useState<string>("")
  
  const [initializationCount,setInitializationCount] : any = useState(-1)
  const [nextIsTrigger,setNextIsTrigger] : any = useState(false)
  
  const [hasInitialization,setHasInitialization] : any = useState(false)
  const [canAddTrigger,setCanAddTrigger] : any = useState(false)
  const [canAddRule,setCanAddRule] : any = useState(false)
  
  const [nodeHasBeenSet,setNodeHasBeenSet] : any = useState(false)
  
  const [nodeTop,setNodeTop] : any = useState(40)

  const nodeTypes = useMemo(
    () => ({
      customNode: StartNodeComponent,
      ifElseNode: IfElseNodeComponent,
      ruleTimeDelay: (nodeProps:any) => <TimeDelayNodeComponent {...nodeProps} setNodes={setNodesFunc} />,
      emailNode: (nodeProps:any) => <EmailCampaignNodeComponent {...nodeProps} setNodes={setNodesFunc} />
    }),
    [],
  );

  const reactFlowProps: ReactFlowProps = {
    proOptions: {
      hideAttribution: true,
    },
    nodeTypes: nodeTypes
  };


  // automation flow initializations/actions/triggers/rules
  const initializations = [
    {
      name: "Subscribes",
      onClick: () => (!hasInitialization) ? onAddInitializationNode(Initializations.SUBSCRIBER_CREATED) : "",
      className: !hasInitialization ? "bg-indigo-600" : "bg-gray-800"
    }
  ]
  const actions = [
    {
      name: "Send email",
      onClick: () => hasInitialization && onAddEmailActionNode(Actions.EMAIL_SENT),
      className: hasInitialization ? "bg-indigo-600" : "bg-gray-800"
    }
  ]
  const triggers = [
    {
      name: "Opens email",
      onClick: () => (canAddTrigger && !nextIsTrigger && hasInitialization) ? onAddTriggerNode(Triggers.EMAIL_OPENED) : "",
      className: (canAddTrigger && !nextIsTrigger && hasInitialization) ? "bg-indigo-600" : "bg-gray-800"
    }
  ]
  const rules = [
    {
      name: "Time delay",
      onClick: () => (canAddRule && hasInitialization) && onAddRuleNode(Rules.TIME_DELAY),
      className: (canAddRule && hasInitialization) ? "bg-indigo-600" : "bg-gray-800"
    },
    {
      name: "If/Else",
      onClick: () => (canAddRule && hasInitialization) && onAddRuleNode(Rules.IFELSE),
      className: (canAddRule && hasInitialization) ? "bg-indigo-600" : "bg-gray-800",
      hidden:true
    },
    {
      name: "Percentile split",
      onClick: () => {},
      className: hasInitialization ? "bg-indigo-600" : "bg-gray-800",
      hidden:true
    },
    {
      name: "Wait for trigger",
      onClick: () => {},
      className: hasInitialization ? "bg-indigo-600" : "bg-gray-800",
      hidden:true
    }
  ]
  
  const onConnect = useCallback((params:any) => {
    let canConnect = true

    // initNode can connect to actions
    // same triggers can't connect to each other
    
    // connecting to itself is not allowed
    if (params.source === params.target){
      canConnect = false;
      alert("You can't connect nodes to itself")
    }

    // check that user isn't connecting initialization node to any other node that action node
    if (params.source.split(":")[0] === "initNode" && params.target.split(":")[0] !== "action"){
      canConnect = false;
      alert("You can only connect Actions to Initializations")
    }

    // check that trigger node isn't connecting to similar trigger node
    if (
      params.source.split(":")[0] === params.target.split(":")[0] && params.source.split(":")[1] === params.target.split(":")[1]
    ){

      canConnect = false;
      alert("You can't connect similar 2 Trigger nodes in a row")
    }

 
    // disable the ability to use same connection point multiple times
    for (let i = 0; i < edges.length; i++) {
      if (edges[i].source === params.source || edges[i].target === params.target) {
        alert('handle already used')
        canConnect = false;
        break;
      }
    }

   
    // Only add the edge if neither the source nor the target node violates the 1-1 connection rule
    if (canConnect) {
      setEdgesFunc((eds:any) => addEdge(params, eds))
    } 

  }, []);

  

  // INTIALIZATION
  const onAddInitializationNode = (label: Initializations) => {
    setHasInitialization(true); // initalized --> user can now add other types of nodes
    let id = `INITNODE:${label}:${initializationCount+1}` 
    let nodeType = "default"
    addNewNode(id,nodeType,label,initializationCount,nodes,0)
  };



  // ACTIONS
  const onAddActionNode = (label: Actions) => { 
    setNextIsTrigger(false) 
    let id = `initNode::${initializationCount+1}` 
    let nodeType = "default"
    addNewNode(id,nodeType,label,initializationCount,nodes,nodeTop) 
  };
  
  const onAddEmailActionNode = (label: Actions) => {

    setCanAddTrigger(true) // action node --> user can now add a trigger
    setNextIsTrigger(false) // set that the next element is not a trigger
    setCanAddRule(true) // tell rules can be set
 
    let id = `ACTION:${label}:${nodes.length + 1}`
    let nodeType = "emailNode"
 
    addNewNode(id,nodeType,label,initializationCount,nodes,nodes.length === 2 ? 80 : 170)
  };



  // TRIGGERS
  const onAddTriggerNode = (label: Triggers) => {

    setCanAddTrigger(false) // is allowed to add trigger as next node 
    setCanAddRule(true) // tell rules can be set


    let id = `TRIGGER:${label}:${initializationCount+1}`
    let nodeType = "default"
    addNewNode(id,nodeType,label,initializationCount,nodes,150)

  };

  
  // RULES
  const onAddRuleNode = (label: Rules) => {

    let id =  `RULE:${label}:${nodes.length + 1}`;
    //let id =  `${label}:${nodes.length + 1}`;

    if (label === Rules.IFELSE){
      addNewNode(id,"ifElseNode",label,initializationCount,nodes,nodeTop)

    } else if (label === Rules.TIME_DELAY) {
      addNewNode(id,"ruleTimeDelay",label,initializationCount,nodes,150)

    }

  };
  
  const automaticallyAddEdge = (id:any) => {
    if (nodes.length > 0) {
      const lastNode = nodes[nodes.length - 1];
      const newEdge = {
        id: id,
        source: lastNode.id,
        target: id
      };

      // Add new edge
      setEdgesFunc([...edges, newEdge]);
    }
  }

  // function to add each node to the view
  const addNewNode = (id:any,nodeType:any,label:any,initializationCount:number,nodes:any,nodeTopAddition:any) => {

    let newNodeTop = nodeTop+nodeTopAddition
    initializationCount >= 0 && automaticallyAddEdge(id) // initial count is -1

    setInitializationCount(initializationCount+1) // node count
    setNodeTop(newNodeTop) // add margin top to previous node (if index = 2 (first action/rule --> add twice the margintop)
    setNodeHasBeenSet(true) // tell other nodes they can be used now

    let elm = nodes.concat(newNode(id,nodeType,label,initializationCount,nodes,newNodeTop)) // ad the actual node
    setNodesFunc(elm); // set node state to view    
  }


  const handleNodeDelete = (elementsToRemove:any) => {
    
    if (elementsToRemove[0].id==="initNode-0"){
        setHasInitialization(false)
        navigate(0);
       
    }
    if (elementsToRemove[0].type === "emailNode"){
      setNodeTop(nodeTop-160)
    } else if (elementsToRemove[0].type === "ruleTimeDelay") {
      setNodeTop(nodeTop-150)
    } else {
      setNodeTop(nodeTop-150)
    }

    // reset nodeTop if it's node "0"
    if (nodes.length <= 3){
      setNodeTop(40)
    }

    let filteredNodes = (currNodes:any) => currNodes.filter((node:any) => !elementsToRemove.some((elem:any) => elem.id === node.id))
    setNodesFunc(filteredNodes)
    let filteredEdges = (currEdges:any) => currEdges.filter((edge:any) => !elementsToRemove.some((elem:any) => elem.id === edge.id))
    setEdgesFunc((currEdges:any) => currEdges.filter((edge:any) => !elementsToRemove.some((elem:any) => elem.id === edge.id)));

    setFlow(filteredEdges)
  };

  const handleEdgeDelete = (edgeToRemove:any) => {
    console.log("edgeToRemove")
  }

  const onEdgesChange = useCallback((changes: any) => {
    setEdgesFunc((eds: any) => applyEdgeChanges(changes, eds));
  }, [setEdges]);

  const onNodesChange = useCallback((changes: any) => {
    setNodesFunc((nds: any) => applyNodeChanges(changes, nds));
  }, [setNodes]);

  const setNodesFunc = (newNodes:any) => {
    setNodes(newNodes)
    setFlow(newNodes)
  }
  const setEdgesFunc = (newEdges:any) => {
    setEdges(newEdges)
    setFlowEdges(newEdges)
  }
 
  return (
    <>

        <div  className="flex ">

          <div className='basis-1/3 p-10 text-white'>
            <h3 className='font-bold text-xl'>Automation flow</h3>
            <p className="text-sm text-gray-400">
              Select a trigger to start building the flow.
            </p>

            <div className="sm:col-span-3 mt-5">
                <TitleFieldWrapper name='name' title='Automation flow name'>
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    required
                  />
                </TitleFieldWrapper>
                
              </div>

              <EventList title="Initializations" list={initializations}/>
              <EventList title="Actions" list={actions}/>
              <EventList title="Triggers" list={triggers}/>
              <EventList title="Rules" list={rules}/>

              <SubmitButtonArea submit={(e:any)=>submit({...e, title})}/>


          </div>


          {/** view of the automation flow */}
          <div className='basis-2/3 bg-gray-950 min-h-[calc(100vh-4em)]'>
              
            <div className="w-full h-full">
              <ReactFlow
                {...reactFlowProps} 
                nodes={nodes} 
                edges={edges}
                nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                connectionLineStyle={connectionLineStyle}
                onConnect={onConnect}
                onNodesDelete={handleNodeDelete}
                onEdgesDelete={handleEdgeDelete}
                defaultViewport={{x:350,y:50,zoom:1.25}}
              >
                <Controls/>
                {/*<Background />*/}
              </ReactFlow>
            </div>

          </div>
        
        </div>
      
    </>
  )
}
