import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './helpers/AuthContext';
import { TeamProvider } from './helpers/TeamContext';
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: 'https://eu.posthog.com',
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <PostHogProvider 
      apiKey={"phc_9zbiYvRX5LYpGyanWJHdOFX52bOmFRdbQ1er0I7vGt"}
      options={options}
    >
    <AuthProvider>
    <TeamProvider>
      <App />
    </TeamProvider>
    </AuthProvider>
    </PostHogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
