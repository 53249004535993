
export const StartNodeComponent: React.FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ color: 'white', marginBottom: '4px' }}>Start</div>
      <svg width="25" height="25">
        <circle cx="12" cy="12" r="12" fill="#6366f1" />
      </svg>
    </div>
  );
};

export function newNode(id:any,nodeType:any,label:any,initializationCount:any,nodes:any,nodeTop:any){
  let newNode;
  label = label.replaceAll("_"," ") 
  if (nodeType === "emailNode"){

    newNode = {
      id: id,
      type: "emailNode", // Assuming you're using the default node type; adjust as needed
      data: { label },
      deletable: true,
      style: {backgroundColor:"transparent",color:"#fff",width:250},
      draggable: (nodes.length <= 1 ? false : true),
      position: { x: -50, y: nodeTop }, // Random position; adjust as needed
    };
    
  } else if (nodeType==="ifElseNode") {

    newNode = {
      id: id,
      type: "ifElseNode", // Assuming you're using the default node type; adjust as needed
      data: { label },
      deletable: true,
      style: {backgroundColor:"transparent",color:"#fff"},
      draggable: (nodes.length <= 1 ? false : true),
      position: { x: (nodes.length <= 1 ? 0 : 0), y: nodeTop }, // Random position; adjust as needed
    };

  } else if (nodeType==="ruleTimeDelay") {

    newNode = {
      id: id,
      type: "ruleTimeDelay", // Assuming you're using the default node type; adjust as needed
      data: { label },
      deletable: true,
      style: {backgroundColor:"transparent",color:"#fff"},
      draggable: (nodes.length <= 1 ? false : true),
      position: { x: 20, y: nodeTop }, // Random position; adjust as needed
    };

  } else {
 
    newNode = {
      id: id,
      type: (nodes.length <= 1 ? "input" : "default"), // Assuming you're using the default node type; adjust as needed
      data: { label },
      deletable: true,
      style: {backgroundColor:"#6366f1",color:"#fff",width:200},
      draggable: (nodes.length <= 1 ? false : true),
      position: { x: (nodes.length <= 1 ? -25 : -25), y: nodeTop }, // Random position; adjust as needed
    };

  }
  
  return newNode
}