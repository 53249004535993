
export default function Header({title,children}:{title:string,children?:any}){
    return (
        <header className="bg-gray-900 border-b-[1px] border-gray-800">
            <div className="flex items-center justify-start gap-5  px-4 py-6 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-gray-400">{title}</h1>
                {children}
            </div>
        </header>
    )
}