import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/icon.png"
import {supabase} from "../../helpers/supabase"

export default function VerifyToken() {

    const navigate = useNavigate();

    const [error,setError] : any = useState()
      
 
    useEffect(() => {
        const session = supabase.auth.getSession();
    
        session.then((res) => {

            if (res.data.session && (!localStorage.getItem("resetpassword")||localStorage.getItem("resetpassword")==="false")) {
                navigate(0); // Navigate to the home page or dashboard
            } else {
                // If there's no session, the user is not logged in or the login failed
                navigate("/signin");
            }

        })

    }, [navigate]);

         

    return (
        <div className="flexjustify-center w-4/5 mx-auto my-12">
            
            {error ? 
            <>
            <img className="h-12 " alt="Logo" src={Logo}/>
            <h2 className="mt-20 text-white text-3xl font-bold">Whoops!</h2>
            <p className="mt-2 text-white text-sm">{error}</p>
            </>
            : <></>}
        </div>
    )
 
}
  