import { useEffect, useState } from "react";


export function InterestsInputSnippet({value=null,setInterestsValue}:{value?:any,setInterestsValue:any}){

  const [interests, setInterests] = useState([{ id: 1, value: '' }]);

  useEffect(() => {
    // used in editsubscriber to add the values
    if (value !== null && value.length >= 1){
      let arr = []
      for (let i = 0; i < value.length; i++){
        arr.push({id:i,value: value[i]})
      }

      if (arr.length >= 1){
        setInterests(arr)
        setInterestsValue(arr)
      }
    }
  },[])

 


  // Function to add a new tag
  const addNewInterest = () => {
    const newInterest = { id: interests.length + 1, value: '' };
    setInterestsValue([...interests, newInterest]); // set parent interests
    setInterests([...interests, newInterest]);
  };

  // Function to handle change in select option for each tag
  const handleInterestChange = (id:any, newValue:any) => {
    let handleInterestChange = interests.map(interest => interest.id === id ? { ...interest, value: newValue } : interest)
    setInterestsValue(handleInterestChange);  // set parent interests
    setInterests(handleInterestChange);
  };

  // Function to remove a tag
  const removeInterest = (id:any) => {
    let removeInterest = interests.filter(interest => interest.id !== id)
    setInterestsValue(removeInterest);  // set parent interests
    setInterests(removeInterest);
  };


  return ( 
    <>
    <label className="block text-sm font-medium leading-6 text-white">Interests</label>
    {interests.map((interest, index) => (
      <div key={interest.id} className="mt-2 items-center gap-2 flex">
        <input 
          type="text"
          id={`interests-${interest.id}`}
          name={`interests-${interest.id}`}
          autoComplete="interests-name"
          value={interest.value}
          className="block w-96 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
          onChange={(e) => {
            handleInterestChange(interest.id, e.target.value);
          }}
        />
        {interests.length > 1 && (
          <div onClick={() => removeInterest(interest.id)} className='cursor-pointer basis-15'>
            <p className="text-sm font-semibold leading-6 text-red-500">- Remove</p>
          </div>
        )}
      </div>
    ))}

    <div onClick={addNewInterest} className='cursor-pointer basis-15 mt-3'>
    <p className="text-sm font-semibold leading-6 text-white">+ Add interest</p>
    </div>
    </>
  )
}