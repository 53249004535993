
type TYPE = "submit" | "button"

export function Button({title,onClick,type}:{title:string,onClick?:any,type?:TYPE}){
  return (
    <button
    type={type}
    onClick={onClick}
    className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
    >
    {title}
    </button>
  )
}