
import { Link, useNavigate } from 'react-router-dom'
import Header from '../../../components/layout/Header'
import { supabase } from '../../../helpers/supabase';
import { useContext } from 'react';
import { TeamContext } from '../../../helpers/TeamContext';

export default function NewCustomerTag() {

  const navigate = useNavigate()
  const {team} = useContext(TeamContext)

    // submit function
    let submit = async (e:any) => {
      e.preventDefault();

      const tag = e.target[0].value.toLowerCase()
      const description = e.target[1].value

      if (!tag){
        alert("Tag cannot be empty")
        return;
      }

      const teamId = JSON.parse(team)[0].team_id

      if (!teamId){
        alert("Couldn't find team id")
        return;
      }

      // check if email exist in subscriber list
      const {data,error} = await supabase.from("customer_tags").select("*").filter("team_id","eq",teamId).filter("tag","eq",tag)

      if (error){
        alert(error);
        return;
      }
 
      if (data != null && data.length >= 1){
        alert("Tag already exists among customer tags");
        return;
      }

      // insert subscriber
      await supabase.from("customer_tags").insert({
        team_id: teamId,
        tag: tag,
        description: description,
      }).then((res) => {
        navigate("/customer-tags",{state:{newtag:true}})
      })




    }


  return (
    <>
    <Header title="New Customer tag"/>

    <form onSubmit={submit} className="mt-10 mx-auto sm:px-6 lg:px-8">
      <div className="border-b border-gray-800 mt-10 pb-10">
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 w-3/5 ">

      <div className="sm:col-span-4 col-span-3">
        <label htmlFor="tag" className="block text-sm font-medium leading-6 text-white">
          Tag name
        </label>
        <div className="mt-2">
          <input
            id="tag"
            name="tag"
            type="tag"
            autoComplete="tag"
            className="block w-full px-1.5 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
        </div>
      </div>



 


      <div className="sm:col-span-4 col-span-3">
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
          Tag description
        </label>
        <div className="mt-2">
            <textarea placeholder="Tag description"
                      className="block w-full px-1.5 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      ></textarea>

        </div>
      </div>



      
    
    </div>
    </div>

    <div className="mb-6 mt-6 flex items-center justify-start gap-x-6">
            <Link to="/customer-segments" className="text-sm font-semibold leading-6 text-white">
            Cancel
            </Link>
            <button
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
            Save
            </button>
        </div>

    </form>
    </>
  )
}
