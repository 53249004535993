import { Fragment, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom' 

import Header from '../../../components/layout/Header'
import { supabase } from '../../../helpers/supabase'
import { TeamContext } from '../../../helpers/TeamContext'
 
export default function ViewCampaignForUser() {
  let { id, generatedEmailId } = useParams();
  let {teamId} = useContext(TeamContext)

  const [campaignName,setCampaignName] = useState<string>("");
  const [generatedEmail, setGeneratedEmail] : any = useState("")

  useEffect(() => {
    async function getEmail(){
      const {data,error} :any = await supabase.from("email_models").select("*").filter("team_id","eq",teamId).filter("id","eq",id)

        const emailsGeneratedForUser = await supabase.from("email_generated_for_users").select(`
        id, 
        subscriber,
        email_model,
        email_generated,
        delivered,
        opened,
        clicked,
        created_at,
        team_id,
        subscriber ( first_name, last_name, email )
      `).filter("team_id","eq",teamId).filter("id","eq",generatedEmailId)

        if (emailsGeneratedForUser.error){
            alert("An error occoured");
        } else {

            const emailsGenerated = emailsGeneratedForUser.data[0]

            setCampaignName(data[0].name)
            setGeneratedEmail(emailsGenerated)

        }

    }
    getEmail()
  },[])

  if (generatedEmailId === undefined){
    return <p>Error</p>
  }

  if (generatedEmail===""){
    return <p>Loading</p>
  }

 
  return (
    <div className='overflow-hidden h-[100vh - 90rem]'>

      <Header title={`${campaignName}`}>
        <p className='text-gray-400 text-sm'>
          {generatedEmail.subscriber.first_name 
              ? 
                "Generated for " + 
                generatedEmail.subscriber.first_name + 
                (generatedEmail.subscriber.last_name ? 
                  " " + generatedEmail.subscriber.last_name:"") + " and " : ""
          }
          sent to {generatedEmail.subscriber.email}</p>
      </Header>


      <div className='h-[calc(100vh-10rem)] bg-white overflow-hidden'>
        <iframe className="w-full h-full" srcDoc={generatedEmail.email_generated} title="email for user"/>
      </div>

    </div>
  )
}
