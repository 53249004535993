
import { Link, useNavigate } from 'react-router-dom'
import Header from '../../../components/layout/Header'
import { PhotoIcon } from '@heroicons/react/24/outline'
import { useContext, useState } from 'react'
import { SUBSCRIBER } from './subscriberEnum'
import Papa from 'papaparse'; 
import { axios } from '../../../helpers/axios'
import { TeamContext } from '../../../helpers/TeamContext'



const secondaryNavigation = [
    { name: 'Single subscriber', href: '/subscribers/new', current: false },
    { name: 'Import subscribers', href: '/subscribers/import', current: true },
  ]
  

// Mock data: Replace with your actual platform's fields
const platformFields : SUBSCRIBER[] = Object.values(SUBSCRIBER)

enum STEPS {
    uploadCSV,
    mapValues,
    importSubscribers
}

export default function ImportSubscribers() {

  const navigate = useNavigate()

  const {teamId} = useContext(TeamContext)

  const [steps, setSteps] = useState<STEPS>(STEPS.uploadCSV); // step management to show different pages
  const [importedFields, setImportedFields]:any = useState([]); // State to hold the uploaded file's fields (e.g., from a CSV header)
  const [overviewImportedData, setOverviewImportedData]:any = useState([]); // State to hold the uploaded file's fields (e.g., from a CSV header)
  const [filename,setFilename] = useState("") // store the filename and show on step one
  const [file,setFile] = useState(); // store file for final use
  const [firstRowValues, setFirstRowValues] : any = useState({}); // Store the first row's values
  const [fieldMappings, setFieldMappings]  : any = useState({}); // mapped data points from CSV to internal data points

  // Handle file upload and parse
  const handleFileUpload = (event:any) => {
    const file = event.target.files[0];

    if (file && file?.size) {

        Papa.parse(file, {
            complete: function(results:any) {
                const headers : any = (results.data[0]); // Assuming the first row contains headers
                const headerKeys = Object.keys(headers);

                setImportedFields(headers);
                setOverviewImportedData([results.data[0],results.data[1],results.data[2]])

                // Guess mappings based on headers
                const guessedMappings = guessMappings(headerKeys);
                setFieldMappings(guessedMappings);
                setFirstRowValues(headers);
            },
            header: true // Tells PapaParse to interpret the first row as headers
        });

        setFilename(file.name)
        setFile(file)
         

    } else if (!file?.size) {
        alert("file was empty")
    }
  };

/*
  const processFirstRowCSVData = (results:any) => {
    console.log(results)
    
    for (const [key, value] of Object.entries(results)) {   
      if (key.toLowerCase().includes("address") && !key.toLowerCase().includes("email")) {
        // Parse the single-string address into components
        console.log(value)
        
        const { address, zipCode, region, city, country } = parseAddress(value);
        console.log(address, zipCode, city, country)
        // Replace or append the parsed data into the row
        results['Address'] = address;
        results['Zip_Code'] = zipCode;
        results['region'] = region;
        results['City'] = city;
        results['Country'] = country;
      }
    }
    return results;
    
  
  };
  
  function parseAddress(addressString:any) {
    const parts = addressString.replace(/\s\s+/g, ' ').split(' '); // replace multi-space with single space and split
    
    const country = parts.pop(); // Last part is the country
    const zipCode = parts.pop(); // Preceding last part is the zip code
    const region = parts.pop(); // Preceding last part is the zip code
  
    const address = parts.shift(); // Assumes the first part is the street address
    const city = parts.join(' '); // Everything else is considered the city
  
    return { address, zipCode, region, city, country };
  }
  */

   // This function attempts to match platform fields to imported fields based on similarity
   const guessMappings = (importedHeaders:any) => {
    const mappings : any = {};

    platformFields.forEach((platformField) => {
      let bestMatch = '';
      let bestMatchScore = 0;

      importedHeaders.forEach((header:any) => {
        const score = similarityScore(platformField.toLowerCase(), header.toLowerCase());

        if (score > bestMatchScore) {
          bestMatch = header;
          bestMatchScore = score;
        }
      });

      if (bestMatch) {
        mappings[platformField] = bestMatch;
      }
    });

    return mappings;
  };


  // A simple function to score similarity based on substring matches
  // This is a very basic approach and can be replaced with more advanced algorithms
  const similarityScore = (str1:any, str2:any) => {
    if (str1 === str2) return 1; // Perfect match
    if (str1.includes(str2) || str2.includes(str1)) return 0.9; // If one string contains the other
    // Check for common substrings (basic version)
    const commonSubstrings = str1.split(' ').filter((value:any) => str2.includes(value));
    return commonSubstrings.length / str1.split(' ').length; // Ratio of common substrings to total substrings
  };
 
  // Update the field mapping
  const updateMapping = (platformField:any, importedField:any) => {
    setFieldMappings((prev:any) => ({ ...prev, [platformField]: importedField }));
  };


  // Transform the overview data to use the correct field names based on mappings
  const transformDataForOverview = (data:any, mappings:any) => {
    return data.map((row:any) => {
      let transformedRow :any= {};
      Object.keys(mappings).forEach(internalField => {
        const csvField = mappings[internalField];
        transformedRow[internalField] = row[csvField] || ""; // Use mapped field name or fallback to an empty string
      });
      return transformedRow;
    });
  };

  const transformFieldMappings = (fieldMappings:any, SubscriberEnum:any) => {
     const invertedEnum = Object.entries(SubscriberEnum).reduce((acc:any, [key, value]:[any,any]) => {
      acc[value] = key;
      return acc;
    }, {}); 

  
    const transformedMappings:any = {};
    Object.keys(fieldMappings).forEach((key:any) => {
      // Use the invertedEnum to get the enum key by its value
      const enumKey = invertedEnum[key];
      if (enumKey) {
        transformedMappings[enumKey] = fieldMappings[key];
      } else {
        console.warn(`No enum key found for value "${fieldMappings[key]}"`);
      }
    });
    return transformedMappings;
  };

  const submit = () => {
    if (fieldMappings && file){
      if (!transformFieldMappings(fieldMappings,SUBSCRIBER)){
        alert("Couldn't transform data fields")
      }
      
      // Create a FormData object
      let formData = new FormData();
      formData.append("file", file);
      formData.append("field_mappings", JSON.stringify(transformFieldMappings(fieldMappings,SUBSCRIBER))); // Add 'fieldmappings' to formData
      formData.append("team_id", teamId); // Add 'team_id' to formData

      axios.post(process.env.REACT_APP_API+"/v1/subscribers/import", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if (res){
          navigate("/subscribers")
        }
      }).catch((error) => {
        // Handle error
        console.error("Error uploading file:", error);
      });

    }
  }

  return (
    <>
    <Header title="Import subscribers"/>
    {/* Secondary navigation */}
    <nav className="flex overflow-x-auto py-4">
        <ul
        className="flex flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
        >
        {secondaryNavigation.map((item) => (
            <li key={item.name}>
                <Link to={item.href} className={item.current ? 'text-indigo-600' : ''}>
                {item.name}
                </Link>
            </li>
        ))}
        </ul>


        <div className='basis-6/12'>
            <div className="mt-2" aria-hidden="true">

                <div className="overflow-hidden rounded-full bg-gray-200">
                    <div className={`h-2 rounded-full bg-indigo-600 ${steps===STEPS.uploadCSV ? "w-1/12" : (steps===STEPS.mapValues ? "w-2/3" : "w-full")}`} />
                </div>

                <div className="mt-6 hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
                <div className={`${steps===STEPS.uploadCSV && "text-indigo-600"}`}>1. Upload subscriber CSV</div>
                <div className={`${steps===STEPS.mapValues && "text-indigo-600"} text-center`}>2. Map subscriber values</div>
                <div className={`${steps===STEPS.importSubscribers && "text-indigo-600"} text-right`}>3. Confirm & import</div>
                </div>
            </div>
        </div>

    </nav>

    <main className="mx-auto sm:px-6 lg:px-8">

        {steps===STEPS.uploadCSV && <label htmlFor="file" className="cursor-pointer block text-sm font-medium leading-6 text-white">
            <div className="border-b pb-10 border-white/10  col-span-full mt-10">
                <p className="block text-sm font-medium leading-6 text-white">
                Upload your import file to get started
                </p>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
                    <div className="text-center">
                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
                        <div className="mt-4 flex justify-center text-sm leading-6 text-gray-400">
                            <div
                                className="
                                relative cursor-pointer 
                                rounded-md bg-gray-900 
                                font-semibold text-white 
                                focus-within:outline-none focus-within:ring-2
                                focus-within:ring-indigo-600 
                                focus-within:ring-offset-2 
                                focus-within:ring-offset-gray-900
                                hover:text-indigo-500"
                            >
                                <span>Upload a file</span>
                                <input onChange={handleFileUpload} id="file" accept=".csv" type="file" className="sr-only" />
                            </div>
                            <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
                        {filename && <p className="mt-2 text-xs text-center leading-5 text-gray-400">Using {filename}</p>}
                    </div>
                </div>
            </div>
        </label>}

        {steps===STEPS.mapValues && 
        <div>
          <h3 className='text-white font-bold mb-5 text-xl'>Map Fields</h3>
          <div className='bg-gray-800 p-5'>
            <div className='w-full text-white font-bold flex mb-3 pb-3 border-b items-center'>
                <div className='basis-[100px]'>Value</div>
                <div className='basis-[230px]'>Imported value</div>
                <div className='basis-auto'>First row values</div>
            </div>

          {platformFields.map((platformField:any) => (
            <div className='w-full flex  items-center' key={platformField}>
              <label className='basis-[100px] text-md text-white'>{platformField}:</label>
              <select 
                onChange={(e) => updateMapping(platformField, e.target.value)} value={fieldMappings[platformField] || ''}
                className='bg-gray-950 w-[220px] text-white'
              >
                <option value="">Select a field</option>
                {Object.entries(importedFields).map(([k,v]) => (
                  <option key={k} value={k}>{k}</option>
                ))}
              </select>
              <div className='basis-auto ml-4 text-white'>
                {fieldMappings[platformField] ? firstRowValues[fieldMappings[platformField]] : ''}
              </div>

            </div>
          ))}
          </div>
        </div>
      }
      

      {steps===STEPS.importSubscribers && 
       <div>
       <h3 className='text-white font-bold mb-5 text-xl'>Overview (first 3 rows)</h3>
       <div className='bg-gray-800 p-0 overflow-x-auto'>
      {overviewImportedData.length > 0 && (
        <>
          <table className='w-full text-sm text-left text-white'>
            <thead className='text-xs text-white uppercase bg-gray-700'>
              <tr>
                {Object.keys(fieldMappings).map((internalField) => (
                  <th key={internalField} scope='col' className='px-6 py-3'>
                    {internalField}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {transformDataForOverview(overviewImportedData.slice(0, 3), fieldMappings).map((row:any, index:any) => (
                <tr key={index} className='border-b border-gray-700'>
                  {Object.values(row).map((value:any, valueIndex) => (
                    <td key={valueIndex} className='px-6 py-4'>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
     </div>
      }


        <div className="mt-6 mb-6 flex items-center justify-start gap-x-6">
            {steps === STEPS.uploadCSV ? <Link to="/subscribers" className="text-sm font-semibold leading-6 text-white">
            Cancel
            </Link> : 
            (steps === STEPS.mapValues) ? 
            <div onClick={() => setSteps(STEPS.uploadCSV)} className="text-sm font-semibold leading-6 text-white">Back</div> : 
            <div onClick={() => setSteps(STEPS.mapValues)} className="text-sm font-semibold leading-6 text-white">Back</div>}
            <button
            onClick={() => {
                if (importedFields && steps===STEPS.uploadCSV){
                    setSteps(STEPS.mapValues)
                } else if (steps === STEPS.mapValues){
                  setSteps(STEPS.importSubscribers)
                } else if (steps === STEPS.importSubscribers){
                  submit()
                }
            }}
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
            {steps === STEPS.importSubscribers ? "Import subscribers" : "Continue"}
            </button>
        </div>

    </main>
    </>
  )
}
