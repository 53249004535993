import { useContext, useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import { TeamContext } from "../../../../helpers/TeamContext";
import { supabase } from "../../../../helpers/supabase";

export const EmailCampaignNodeComponent = ({ id,data, setNodes }:any) => {
  const {teamId} = useContext(TeamContext)
  const [emails,setEmails] = useState([])
  useEffect(() => {

    // initialize new value to be able to check if nodes with values are empty
    setNodes((nds:any) =>
      nds.map((node:any) =>
        !node.data.value && node.id === id ? { ...node, data: { ...node.data, value:"" } } : node
      )
    );

    async function getEmails(){

    const {data}:any = await supabase.from("email_models").select("*").eq("team_id",teamId)
    if(data!=null && data.length >= 1){
      setEmails(data)
    }
    }
    getEmails()
  },[])


  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
 
    // Update the node's data with the selected campaign
    setNodes((nds:any) =>
      nds.map((node:any) =>
        node.id === id ? { ...node, data: { ...node.data, value } } : node
      )
    );
  };

  return (
    <div className="bg-indigo-950 border border-gray-900 p-3 w-[250px] overflow-hidden text-center rounded-md">
      {/* Entry handle */}
      <Handle type="target" position={Position.Top} style={{ borderRadius: 100 }} />
      <div className="text-xs">
        {data.label}
      </div>

      <div>
        
        <label htmlFor="emailcampaign" className="text-[10px] block mt-5 font-medium leading-6 text-white">
          Email campaign
        </label>
        <div className="mt-2">
        <select
          id="emailcampaign"
          name="emailcampaign"
          className="text-[10px] block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:leading-6 [&_*]:text-black"
          value={data.value || ''} // Set the select value from the node data
          onChange={handleSelectChange} // Set the handler for changes
        >
          <option value="" disabled>Select email</option>
          {emails.map((e:any,idx:number) => {
            return <option key={idx} value={e.shortid}>{e.name}</option>
          })}
        </select>
        </div>

      </div>
      {/* Exit handles */}
      <Handle type="source" position={Position.Bottom} id="a" style={{ top: '97%', left:'50%', borderRadius: 100, background: '#555' }} />
    </div>
  );
};