import { SIDEBARNAVTYPE } from "../../helpers/email/helper";


export default function EmailDisplay({setSampleGeneratedEmailModel,sampleGeneratedEmailModel,sidebarNavigation}:{setSampleGeneratedEmailModel:any,sampleGeneratedEmailModel:string,sidebarNavigation:SIDEBARNAVTYPE}){
    return (<div className='basis-2/3 bg-gray-950 min-h-[calc(100vh-4em)]'>
            
    {!sampleGeneratedEmailModel ? 
      <div className="flex h-full justify-center items-center"><p className="text-xl text-gray-500">Generate context to view sample</p></div> 
      : 
      sidebarNavigation === SIDEBARNAVTYPE.default ? 
        <iframe className="w-full h-full bg-white" title="email" srcDoc={sampleGeneratedEmailModel}/> : 
        <textarea value={sampleGeneratedEmailModel} onChange={(e) => setSampleGeneratedEmailModel(e.target.value)} className='resize-none border-none outline-0 w-full h-full bg-gray-950 text-white'></textarea>
      }
  </div>)
}