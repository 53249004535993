
import Header from '../../../components/layout/Header'

import {  publishingOptions, useGraphState, connectionLineStyle } from '../../../components/layout/AutomationFlows/Nodes';


 
import ReactFlow, { Controls, ReactFlowProps } from 'reactflow'
import { useContext, useEffect, useMemo, useState } from 'react';
import { EmailCampaignNodeComponent } from '../../../components/automationflows/nodes/actions/EmailCampaignNodeComponent';
import { TimeDelayNodeComponent } from '../../../components/automationflows/nodes/rules/TimeDelayNodeComponent';
import { IfElseNodeComponent } from '../../../components/automationflows/nodes/rules/IfElseNodeComponent';
import { StartNodeComponent } from '../../../components/automationflows/nodes/StartNodeComponent';
import { TeamContext } from '../../../helpers/TeamContext';
import { supabase } from '../../../helpers/supabase';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ButtonDelete from '../../../components/input/ButtonDelete';


function classNames(...classes:any) {
return classes.filter(Boolean).join(' ')
}

    
export default function ViewAutomationFlow() {
    
    const {teamId} = useContext(TeamContext)
    const {id} = useParams()
    const navigate = useNavigate()
  const { setNodes, onNodesChange, setEdges, onEdgesChange } = useGraphState();

  const [nodes,setNodesState]:any = useState([])
  const [edges,setEdgesState]:any = useState([])
  const [automationflow,setAutomationflow]:any = useState(null)
  
  useEffect(() => {
    async function getFlows(){
        let {data,errors} : any = await supabase.from("automation_flows").select("*").match({"team_id":teamId,shortid: id}).single()

        if (data !== null){
            console.log(data)
            setAutomationflow(data)
            setEdgesState(data.edges)
            setNodesState(data.nodes)
        }
    }
    getFlows()
},[])

  const nodeTypes = useMemo(
    () => ({
      customNode: StartNodeComponent,
      ifElseNode: IfElseNodeComponent,
      ruleTimeDelay: (nodeProps:any) => <TimeDelayNodeComponent {...nodeProps} setNodes={setNodes} />,
      emailNode: (nodeProps:any) => <EmailCampaignNodeComponent {...nodeProps} setNodes={setNodes} />

    }),
    [],
  );

  const updateStatus = async (type:any) => {
    let val = "ACTIVE"
    if (type === "DRAFT"){
        val = "DRAFT"
    } else if (type === "ACTIVE"){
        val = "ACTIVE"
    } else {
        alert("error")
        return;
    }
    let {data,errors} : any = await supabase.from("automation_flows").update({status:val}).match({"team_id":teamId,shortid: id}).select().single()
    setAutomationflow(data)

  }

  const deleteFlow = async () => {
    let {data,errors} : any = await supabase.from("automation_flows").delete().match({"team_id":teamId,shortid: id})
    if (errors){
        alert(errors)
    } else {
        navigate("/automation-flows")
    }


  }
  if (automationflow === null && nodes.length < 1 && edges.length < 1){
    return <p className='text-white mx-auto mt-10 text-center'>Loading...</p>;
  }

  const reactFlowProps: ReactFlowProps = {
    proOptions: {
      hideAttribution: true,
    }
  };
  return (
    <>
        <Header title={automationflow.name}>

            <div className='flex ml-auto items-center gap-x-4 text-white'>
                <p>Current mode: {automationflow.status}</p>

                {automationflow.status === "DRAFT" ? 
                <button
                onClick={() => updateStatus("ACTIVE")}
                className=" bg-green-700 text-white rounded-md  px-2.5 py-1.5 text-sm font-semibold   shadow-sm   sm:block">
                    Activate
                </button> : 

                <button
                onClick={() => updateStatus("DRAFT")}
                className=" rounded-md bg-orange-800 text-white px-2.5 py-1.5 text-sm font-semibold  shadow-sm  hover:bg-slate-800 sm:block">
                    Deactivate
                </button>}
                <ButtonDelete onClick={() => deleteFlow()}/>
            </div>

        </Header>

        
        <main className=' bg-gray-950'>
              
              <div className="w-full h-96 min-h-[calc(100vh-9.3em)]">
                <ReactFlow
                  {...reactFlowProps}  

                nodes={nodes} 
                edges={edges}
                nodeTypes={nodeTypes}
                connectionLineStyle={connectionLineStyle}
                  defaultViewport={{x:550,y:50,zoom:1.25}}
                >
                  <Controls/>
                  {/*<Background />*/}
                </ReactFlow>
              </div>
  

        </main>
    </>
  )
}
