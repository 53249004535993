export enum SubmitType {
    draft = "DRAFT",
    publish = "ACTIVE"
}

export enum Initializations {
    SUBSCRIBER_CREATED = "SUBSCRIBER_CREATED",
    Unsubscribes = "UNSUBSCRIBES"
}

export enum Triggers {
    EMAIL_OPENED = "EMAIL_OPENED",
    EMAIL_CLICKED = "EMAIL_CLICKED"
}

export enum Actions {
    EMAIL_SENT = "EMAIL_SENT"
}

export enum Rules {
    IFELSE = "IF/ELSE",
    TIME_DELAY = "TIME_DELAY"
}