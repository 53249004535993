import { Listbox, Transition } from "@headlessui/react";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { publishingOptions } from "../../layout/AutomationFlows/Nodes";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";


function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}
 

export function SubmitButtonArea({submit}:{submit:any}){

  const [selected, setSelected] = useState(publishingOptions[0])

  return (
    <div className="mt-10 pt-5 border-t justify-between items-center flex border-gray-800">
              <p className="text-sm color-gray-500 italic">Click to save changes</p>
              
              <Listbox value={selected} onChange={setSelected}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                    <div className="relative">
                      
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {publishingOptions.map((option) => (
                            <Listbox.Option
                              key={option.title}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                  'cursor-default select-none p-4 text-sm'
                                )
                              }
                              value={option}
                            >
                              {({ selected, active }) => (
                                <div className="flex flex-col">
                                  <div className="flex justify-between">
                                    <p className={selected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                                    {selected ? (
                                      <span className={active ? 'text-white' : 'text-indigo-600'}>
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'mt-0')}>
                                    {option.description}
                                  </p>
                                </div>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>

                      <div className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
                        <div onClick={(e) => submit(selected)} className="inline-flex cursor-pointer items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm">
                          <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                          <p className="text-sm font-semibold">{selected.title}</p>
                        </div>
                        <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                          <span className="sr-only">Change published status</span>
                          <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </Listbox.Button>
                      </div>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
  )
}