import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";


//helpers
import PrivateRoute from "./helpers/PrivateRoute"; // Import the PrivateRoute component
import { AuthContext } from "./helpers/AuthContext";
import { useAutoRefreshSession } from "./helpers/useAutoRefreshTokens";


/** pages notloggedin*/
import VerifyToken from "./pages/notloggedin/VerifyToken";

import Signin from "./pages/notloggedin/Signin";
import Signup from "./pages/notloggedin/Signup";
import ForgottenPassword from "./pages/notloggedin/ForgottenPassword";
import VerifyAccount from "./pages/notloggedin/VerifyAccount";

/** pages loggedin*/

//dashboard
import Dashboard from "./pages/loggedin/Dashboard";
//email
import EmailCampaigns from "./pages/loggedin/emails/EmailCampaigns";
import NewCampaign from "./pages/loggedin/emails/NewCampaign";
import ViewCampaign from "./pages/loggedin/emails/ViewCampaign";
import SendCampaign from "./pages/loggedin/emails/SendCampaign";
import ViewCampaignForUser from "./pages/loggedin/emails/ViewCampaignForUser";
//transactional emails
import TransactionalEmails from "./pages/loggedin/transactional/transactional"
//automation flow
import AutomationFlows from "./pages/loggedin/automationFlows/AutomationFlows";
import NewAutomationFlows from "./pages/loggedin/automationFlows/NewAutomationFlow";
import ViewAutomationFlows from "./pages/loggedin/automationFlows/ViewAutomationFlow";
//customer segments
import CustomerSegments from "./pages/loggedin/customerSegments/CustomerSegments";
import NewCustomerSegment from "./pages/loggedin/customerSegments/NewCustomerSegment";
import ViewCustomerSegment from "./pages/loggedin/customerSegments/ViewCustomerSegment";
import EditCustomerSegments from "./pages/loggedin/customerSegments/EditCustomerSegments";
// customer tags
import EditCustomerTag from "./pages/loggedin/customerTags/EditCustomerTag";
import NewCustomerTag from "./pages/loggedin/customerTags/NewCustomerTag";
import CustomerTag from "./pages/loggedin/customerTags/CustomerTags";

//subscribers
import Subscribers from "./pages/loggedin/subscribers/Subscribers";
import EditSubscriber from "./pages/loggedin/subscribers/EditSubscriber";
import NewSubscribers from "./pages/loggedin/subscribers/NewSubscribers";
import ImportSubscribers from "./pages/loggedin/subscribers/ImportSubscribers";
//create new team (required to create account)
import CreateTeam from "./pages/loggedin/teams/CreateTeam";
//settings
import Settings from "./pages/loggedin/Settings";
import ViewTeam from "./pages/loggedin/teams/ViewTeam";
import VerifyDomain from "./pages/loggedin/teams/VerifyDomain";
import ResetPassword from "./pages/loggedin/ResetPassword";
// assets
import BrandAssets from "./pages/loggedin/assets/BrandAssets";



import { TeamContext } from "./helpers/TeamContext";

import LayoutWMenu from "./components/layout/LayoutWMenu";
import Logo from "./assets/icon.png"

function App() {

  const [isResetPassword,setIsResetPassword] : any = useState(undefined)

  const {token } = useContext(AuthContext);// This should be your actual logic to determine if the user is logged in
  const {team } = useContext(TeamContext);// The active team
   
  useAutoRefreshSession(); // Automatically refresh the session

  // when access_token is in the url --> reload page to ensure sb auth token has been set in authcontext
  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1)); // since hash includes the '#' character
    const accessToken = params.get('access_token');
    const type = params.get('type');

    const resetPassword = localStorage.getItem("resetpassword") ? localStorage.getItem("resetpassword") : false
    setIsResetPassword(resetPassword)
    
    if (accessToken) {
      if (type === "recovery"){
        setIsResetPassword(true)
        localStorage.setItem("resetpassword","true")
      } else {
        setTimeout(() => { // timeout to give some time to set the token
          window.location.href = "/"
        },250)
      }
    }
  }, []);

  // if user is loggedin and team is still fetching --> wait for fetching
  if ((token !== null && team===undefined)|| isResetPassword === undefined){
    return <div className="flex justify-center items-center h-screen"><img className="h-20" alt="icon" src={Logo}/></div>
  }

  return (
    <div className="App bg-gray-900 min-h-[100vh]">
      <BrowserRouter future={{ v7_startTransition: true }}>
        
        {token!==null &&  isResetPassword === false ?
        <>
        {JSON.parse(team).length === 0 ?
        <Routes>

          {/** when a user has just registered they don't have a team - but are loggedin */}
          <Route path="/create-team" element={<CreateTeam />} />
          <Route path="/create-team/verify" element={<VerifyDomain />} />
          <Route path="*" element={<Navigate to="/create-team"/>} />

        </Routes>

        :

        <LayoutWMenu>
          <Routes>

            <Route path="/" element={<Dashboard />} />
            <Route path="/emails" element={<EmailCampaigns />} />
            <Route path="/emails/new" element={<NewCampaign />} />
            <Route path="/emails/:id" element={<ViewCampaign />} />
            <Route path="/emails/:id/send" element={<SendCampaign />} />
            <Route path="/emails/:id/:generatedEmailId" element={<ViewCampaignForUser />} />

            <Route path="/transactional" element={<TransactionalEmails />} />
            
            <Route path="/automation-flows" element={<AutomationFlows />} />
            <Route path="/automation-flows/new" element={<NewAutomationFlows />} />
            <Route path="/automation-flows/:id" element={<ViewAutomationFlows />} />

            <Route path="/customer-segments" element={<CustomerSegments />} />
            <Route path="/customer-segments/new" element={<NewCustomerSegment />} />
            <Route path="/customer-segments/:id" element={<ViewCustomerSegment />} />
            <Route path="/customer-segments/manage/:id/edit" element={<EditCustomerSegments />} />


            <Route path="/customer-tags" element={<CustomerTag />} />
            <Route path="/customer-tags/new" element={<NewCustomerTag />} />
            <Route path="/customer-tags/:id" element={<EditCustomerTag />} />
            
            <Route path="/subscribers" element={<Subscribers />} />
            <Route path="/subscribers/manage/:id/edit" element={<EditSubscriber />} />
            <Route path="/subscribers/new" element={<NewSubscribers />} />
            <Route path="/subscribers/import" element={<ImportSubscribers />} />


            <Route path="/assets/brand" element={<BrandAssets />} />

            
            
            <Route path="/teams/:id" element={<ViewTeam />} />
            <Route path="/settings" element={<Settings />} />

            <Route path="*" element={<Navigate to="/" />} />

          </Routes>
        </LayoutWMenu> 

        }
        </>
        : 
        <Routes>
          {/**signed out routes */}
            
            
          {/** GET / used for verification token handling */}
          <Route path="/" element={<VerifyToken />} />

          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/forgotten-password" element={<ForgottenPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify" element={<VerifyAccount />} />


          <Route path="*" element={<Navigate to="/signin" />} />

        </Routes>
        }

      </BrowserRouter>

    </div>
  );
}

export default App;
