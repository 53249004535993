
import { Link, useNavigate, useParams } from 'react-router-dom'
import Header from '../../../components/layout/Header'
import { supabase } from '../../../helpers/supabase';
import { useContext, useEffect, useState } from 'react';
import { TeamContext } from '../../../helpers/TeamContext';

export default function EditCustomerTag() {

  const navigate = useNavigate()
  const {id} = useParams()
  const {team,teamId} = useContext(TeamContext)

  const [currentTag,setCurrentTag] = useState("")
  const [tag,setTag] = useState("")
  const [description,setDescription] = useState("")

  useEffect(() => {
    let getTags = async () => {
      if (!id){
        alert("Couldn't load tag")
        return;
      }

      let {data} = await supabase.from("customer_tags").select("*").filter("team_id","eq",teamId).filter("id","eq",id)
      console.log(data)

      if (data!==null){

        setTag(data[0].tag)
        setCurrentTag(data[0].tag)
        setDescription(data[0].description)

      }

    }
    getTags()
  },[])

  // submit function
  let submit = async (e:any) => {
    e.preventDefault();

    const tag = e.target[0].value
    const description = e.target[1].value

    if (!tag){
      alert("Tag cannot be empty")
      return;
    }

    if (!id){
      alert("Tag cannot be found")
      return;
    }

    const teamId = JSON.parse(team)[0].team_id

    if (!teamId){
      alert("Couldn't find team id")
      return;
    }

    // check if email exist in subscriber list
    const {data,error} = await supabase.from("customer_tags").select("*").filter("team_id","eq",teamId).filter("tag","eq",tag)

    if (error){
      alert(error);
      return;
    }

    if (data != null && data.length >= 1 && currentTag !== tag){
      alert("Tag already exists among customer tags");
      return;
    } else {

      // insert subscriber
      await supabase.from("customer_tags").update({
        tag: tag,
        description: description,
      }).eq("id",id).then((res) => {
        navigate("/customer-tags",{state:{newtag:true}})
      })
    }





  }

  if (!tag){
    return <p>Loading</p>
  }


  return (
    <>
    <Header title="Edit Customer tag"/>

    <form onSubmit={submit} className="mt-10 mx-auto sm:px-6 lg:px-8">
      <div className="border-b border-gray-800 mt-10 pb-10">
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 w-3/5 ">

      <div className="sm:col-span-4 col-span-3">
        <label htmlFor="tag" className="block text-sm font-medium leading-6 text-white">
          Tag name
        </label>
        <div className="mt-2">
          <input
            id="tag"
            name="tag"
            type="tag"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            autoComplete="tag"
            className="block w-full px-1.5 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
        </div>
      </div>



 


      <div className="sm:col-span-4 col-span-3">
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
          Tag description
        </label>
        <div className="mt-2">
            <textarea 
            
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Tag description"
                      className="block w-full px-1.5 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      >{description}</textarea>

        </div>
      </div>



      
    
    </div>
    </div>

    <div className="mb-6 mt-6 flex items-center justify-start gap-x-6">
            <Link to="/customer-segments" className="text-sm font-semibold leading-6 text-white">
            Cancel
            </Link>
            <button
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
            Save
            </button>
        </div>

    </form>
    </>
  )
}
