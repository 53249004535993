import { Link } from "react-router-dom";
import { Button } from "../input/Button";

export function ButtonActions({cancelLink,onClickSubmit}:{cancelLink:string,onClickSubmit?:any}){
  return (
    <div className="mb-6 mt-6 flex items-center justify-start gap-x-6">
        <Link to={cancelLink} className="text-sm font-semibold leading-6 text-white">
        Cancel
        </Link>
        <Button onClick={onClickSubmit} type="submit" title="Save"/>
    </div>
    )
}