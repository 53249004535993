export enum SUBSCRIBER {
  email = "email",
  first_name = "First name",
  last_name = "Last name",
  tags = "tags",
  phone = "phone",
  birthday = "birthday",
  address = "address",
  city = "city",
  region = "State/Region",
  zip_code = "zip code",
  country = "country",
  language = "lanugage",
  interests = "interests",
  member_rating = "Rating",
  timezone = "timezone",
  optin_time = "optin time"
}