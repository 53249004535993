import { useEdgesState, useNodesState } from "reactflow";
import { SubmitType } from "../../../helpers/EnumsAutomationFlow";


export const publishingOptions = [
    { title: 'Save draft', submitType: SubmitType.draft, description: 'Save as draft.', current: false },
    { title: 'Save & activate', submitType: SubmitType.publish, description: 'Activate automation flow now.', current: true },
]

export const initialNodes = [
    { id: '1', type: "customNode", dragHandle: "false", position: { x: 57, y: 0 }, data: { label: 'Trigger (subscriber)' } },
];
 
export const useGraphState = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  
    return { nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange };
  };
  

export const connectionLineStyle = { stroke: 'white' };




  
