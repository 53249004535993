/* eslint-disable no-multi-str */

import Header from '../../../components/layout/Header'
import { Link } from 'react-router-dom'

import { CopyBlock,dracula } from 'react-code-blocks';
import { useContext, useEffect, useState } from 'react';
import { TeamContext } from '../../../helpers/TeamContext';
import { TitleFieldWrapper } from '../../../components/input/TitleFieldWrapper';
import { supabase } from '../../../helpers/supabase';

    
export default function AutomationFlows() {

  const {apiKey,domain,teamName,teamId} = useContext(TeamContext)

  const [count,setCount] = useState(0)

  useEffect(() =>{
    async function getTransactional(){
      const {data} = await supabase.from("transactional_emails").select("count").match({team_id:teamId}).single()
      setCount(data!=null?data.count:0)

    }
    getTransactional()
  },[])
 

  return (
    <>
        <Header title="Transactional Relay API">
          
        </Header>


        <main className="mx-auto sm:px-6 lg:px-8 py-5">

        <div className='flex items-end'>
          <TitleFieldWrapper name="apiKey" title='Your api key'>
          <input readOnly type="text" className='bg-gray-800 text-white w-80' value={apiKey}/>
          </TitleFieldWrapper>
          <p className='ml-5 text-white'>You have sent {count} emails using the relay<br/>You can send 10.000 emails per month. For a higher sent limit please contact us</p>
        </div>

        <h2 className='text-white font-bold text-lg mt-10 mb-5'>Code snippets with your API key</h2>

        <h2 className='text-white font-bold text-lg mb-5'>cURL</h2>

        <CopyBlock
      text={"curl --location 'https://api.notificationharbor.com/v1/smtp/send' \n\
      --header 'Content-Type: application/x-www-form-urlencoded' \n\
      --header 'x-api-key: "+apiKey+"' \n\
      --data-urlencode 'from=hello@"+domain+"' \n\
      --data-urlencode 'name="+teamName+"' \n\
      --data-urlencode 'to=admin@guldstoev.com' \n\
      --data-urlencode 'subject=Hello there subject!' \n\
      --data-urlencode 'html=<p>This is an email body!</p>'"}
      language={"bash"}
      showLineNumbers={true}
      wrapLongLines={false}
      theme={dracula}
      codeBlock
    />

<h2 className='text-white font-bold text-lg mb-5 mt-10'>Javascript - axios</h2>
<CopyBlock
  text={"\
  import axios from 'axios'; // CommonJS: const axios = require('axios'); \n\
  const qs = require('qs'); // qs is used for query string parsing and stringifying\n\
  \n\
  const data = qs.stringify({ from: 'hello@"+domain+"', name: '"+teamName+"', to: 'hello@example.com', subject: 'Hello there!', html: '<p>This is an email body!</p>'});\n\
  \n\
  const config = {\n\
    method: 'post',\n\
    url: 'https://api.notificationharbor.com/v1/smtp/send',\n\
    headers: { \n\
      'Content-Type': 'application/x-www-form-urlencoded',\n\
      'x-api-key': '"+apiKey+"',\n\
    },\n\
    data: data\n\
  };\n\
  \n\
  axios(config).then(function (response) {\n\
    console.log(JSON.stringify(response.data));\n\
  }).catch(function (error) {\n\
    console.log(error);\n\
  });"}
      language={"javascript"}
      showLineNumbers={true}
      wrapLongLines={false}
      theme={dracula}
      codeBlock
      />

<h2 className='text-white font-bold text-lg mb-5 mt-10'>PHP - cURL</h2>
<CopyBlock
  text={"<?php\n\
  \n\
$curl = curl_init();\n\
\n\
curl_setopt_array($curl, array(\n\
  CURLOPT_URL => 'https://api.notificationharbor.com/v1/smtp/send',\n\
  CURLOPT_RETURNTRANSFER => true,\n\
  CURLOPT_ENCODING => '',\n\
  CURLOPT_MAXREDIRS => 10,\n\
  CURLOPT_TIMEOUT => 0,\n\
  CURLOPT_FOLLOWLOCATION => true,\n\
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,\n\
  CURLOPT_CUSTOMREQUEST => 'POST',\n\
  CURLOPT_POSTFIELDS => http_build_query(array(\n\
    'from' => 'hello@"+domain+"',\n\
    'name' => '"+teamName+"',\n\
    'to' => 'receiver@example.com',\n\
    'subject' => 'Hello there subject!',\n\
    'html' => '<p>This is a test2!</p>'\n\
  )),\n\
  CURLOPT_HTTPHEADER => array(\n\
    'Content-Type: application/x-www-form-urlencoded',\n\
    'x-api-key: "+apiKey+"'\n\
  ),\n\
));\n\
\n\
$response = curl_exec($curl);\n\
\n\
curl_close($curl);\n\
echo $response;\n\
?>"}
      language={"javascript"}
      showLineNumbers={true}
      wrapLongLines={false}
      theme={dracula}
      codeBlock
      />






 
        </main>
 
    </>
  )
}
