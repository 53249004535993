import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'


import { supabase } from '../../../helpers/supabase';
import { TeamContext } from '../../../helpers/TeamContext';
import {  useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../../components/email/Sidebar';
import { getSendTypeCountFunction,SENDTYPES,SIDEBARNAVTYPE, updateEmail } from '../../../helpers/email/helper';
import EmailDisplay from '../../../components/email/EmailDisplay';



export default function SendCampaign() {

  const {id} = useParams();
  const navigate = useNavigate()

  const {teamId,teamName,domain} = useContext(TeamContext)
 
  const [questionContextIntent,setQuestionContextIntent] : any = useState(false);  // used to toggle questionmark information above context intent
  const [sidebarNavigation,setSidebarNavigation] = useState<SIDEBARNAVTYPE>(SIDEBARNAVTYPE.default);  // used to toggle questionmark information above context intent
  
  // formdata
  const [campaignId,setCampaignId] = useState<string>(""); // used as reference to update email_models
  const [campaignName,setCampaignName] = useState<string>("");
  const [intent,setIntent] = useState<string>("");
  const [sampleGeneratedEmailModel,setSampleGeneratedEmailModel] = useState<string>(""); // returned sample model by openai
  

  // send now area
  const [sendEmailNow,setSendEmailNow] = useState<boolean>(true);
  const [sendEmailNowLoader,setSendEmailNowLoader] = useState<boolean>(false);
  const [sendType,setSendType] : [SENDTYPES,Dispatch<SetStateAction<SENDTYPES>>] = useState<SENDTYPES>(SENDTYPES.singleEmail);
  const [singleEmailReceiver,setSingleEmailReceiver] = useState<string>("")
  const [customerSegment,setCustomerSegment] = useState<string>("")

  // set count for how many users will receive email
  const [sendNowCountReceivers, setSendNowCountReceivers] = useState<number>(1);
  
  
  useEffect(() => {
    async function getEmail(){
        const {data,error} = await supabase.from("email_models").select("*").filter("team_id","eq",teamId).filter("id","eq",id)
        if (error){
            alert("An error occoured");
        } else {

            const email = data[0]

            setCampaignId(email.id)
            setCampaignName(email.name)
            setIntent(email.intent)
            setSampleGeneratedEmailModel(email.email_model)
            setSendEmailNow(email.send_now) // just set it to true to encourage more peope to send?

            setSendType(email.send_type)
            setSingleEmailReceiver(email.sent_to_single_email)
            setCustomerSegment(email.customer_segment)

            setSendType(email.send_type)
            getSendTypeCountFunction(email.send_type,teamId,setSendNowCountReceivers)
           

        }

    }
    getEmail()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id,teamId])




  if (!id){
    return <p>Loading</p>
  }
       

  return (
    <>

        <form onSubmit={(e)=> {
          e.preventDefault();
          updateEmail({
            id,
            campaignId,
            campaignName,
            intent,
            singleEmailReceiver,
            customerSegment,
            sampleGeneratedEmailModel,
            teamId,
            domain,
            teamName,
            sendEmailNow,
            sendType,
            setSendEmailNowLoader
        }).then((res) => {
          setSendEmailNowLoader(false)
          if (res===true){
            //navigate("/emails")
          }
        }).catch(e => alert(e.message?e.message:e))}} className="flex ">

          
        
         
        <Sidebar
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          intent={intent}
          setIntent={setIntent}
          questionContextIntent={questionContextIntent}
          setQuestionContextIntent={setQuestionContextIntent}
          sendEmailNow={sendEmailNow}
          setSendEmailNow={setSendEmailNow}
          sendType={sendType}
          setSendNowCountReceivers={setSendNowCountReceivers}
          setSendType={setSendType}
          sendNowCountReceivers={sendNowCountReceivers}
          singleEmailReceiver={singleEmailReceiver}
          customerSegment={customerSegment}
          setCustomerSegment={setCustomerSegment}
          setSingleEmailReceiver={setSingleEmailReceiver}
          sendEmailNowLoader={sendEmailNowLoader}
          setSampleGeneratedEmailModel={setSampleGeneratedEmailModel}
          canGenerateContext={false}
          setSidebarNavigation={setSidebarNavigation}
          sidebarNavigation={sidebarNavigation}
        />

      <EmailDisplay setSampleGeneratedEmailModel={setSampleGeneratedEmailModel} sampleGeneratedEmailModel={sampleGeneratedEmailModel} sidebarNavigation={sidebarNavigation}/>
        
        </form>
      
    </>
  )
}
