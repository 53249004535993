import { useContext, useEffect, useState } from "react";
import { UserLocalTime } from "../../helpers/TimeFormat";
import { supabase } from "../../helpers/supabase";
import { TeamContext } from "../../helpers/TeamContext";
import {EllipsisHorizontalIcon,PencilIcon} from '@heroicons/react/24/outline'
import { Link } from "react-router-dom";


function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

export function SubscriberList({subscribers}:{subscribers:any[]}){
  const [tags,setTags] : any = useState([]);
  const {teamId} = useContext(TeamContext)


  useEffect(() => {    
    async function getSubscribers(){

      if (!teamId){
        alert("Can't find teamId")
        return;
      }



      const tags = await supabase.from("customer_tags").select("*").filter("team_id","eq",teamId)

      if (tags.data){
        setTags(tags.data)
      }
    }
    getSubscribers()
    
  },[])

  if (!subscribers) {
    return <p>Loading...</p>
  }

  const classNamesTH = "min-w-32 capitalize sticky top-16 z-10 border-b border-gray-800 bg-gray-950 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-300 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8";
  const classNamesTD = "border-gray-800 border-b whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6 lg:pl-8";


  return (
    <table className=" border-separate border-spacing-0">
              <thead>
                <tr>
                  <th className={classNames(classNamesTH,`p-0 sm:p-0 lg:p-0 min-w-4`)}></th>
                  <th className={"min-w-32 capitalize sticky top-16 z-10 border-b border-gray-800 bg-gray-950 bg-opacity-75 py-0 pl-0 pr-0 text-left text-sm font-semibold text-gray-300 backdrop-blur backdrop-filter sm:pl-0 lg:pl-0"}>email</th>
                  <th className={classNames(classNamesTH)}>First name</th>
                  <th className={classNames(classNamesTH)}>Last name</th>
                  <th className={classNames(classNamesTH)}>phone</th>
                  <th className={classNames(classNamesTH)}>birthday</th>
                  <th className={classNames(classNamesTH)}>tags</th>
                  <th className={classNames(classNamesTH)}>interests</th>
                  <th className={classNames(classNamesTH)}>country</th>
                  <th className={classNames(classNamesTH)}>city</th>
                  <th className={classNames(classNamesTH)}>address</th>
                  <th className={classNames(classNamesTH)}>region</th>
                  <th className={classNames(classNamesTH)}>zip code</th>
                  <th className={classNames(classNamesTH)}>language</th>
                  <th className={classNames(classNamesTH)}>rating</th>
                  <th className={classNames(classNamesTH,"w-full")}>created</th>
 
                     
                </tr>
              </thead>
              <tbody>
                {(subscribers!==null && subscribers.length >= 1) ? subscribers.map((subscriber:any, personIdx:number) => (
                  <tr key={personIdx}>


                    <td className={`border-gray-800 border-b px-3 text-center0`}>
                      <Link to={`/subscribers/manage/${subscriber.id}/edit`}><EllipsisHorizontalIcon  height={25} className="text-gray-600 hover:text-white"/></Link>
                    </td>
                    <td className={"border-gray-800 border-b whitespace-nowrap py-0 pl-0 pr-0 text-sm font-medium text-gray-500 sm:pl-0 lg:pl-0"}>{subscriber.email}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.first_name}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.last_name}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.phone}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.birthday}</td>
                    <td className={classNames(classNamesTD)}>
                    {
                    (subscriber.tags !== null && subscriber.tags !== "" && subscriber.tags.tags.length >= 1) && 
                      subscriber.tags.tags.map((e:any,i:number) => {
                        return (<span key={i} className="text-white bg-indigo-950 text-xs border border-indigo-600 p-1 m-1 rounded-md">
                          {tags.filter((tag:any) => { return (tag.id === e) ? tag.tag : "" })[0]?.tag}</span>)
                      })
                    }
                    </td>
                    <td className={classNames(classNamesTD)}>{(subscriber.interests!= null && subscriber.interests.interests.length >= 1) ? subscriber.interests.interests.join(", ") : ""}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.country}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.city}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.address}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.region}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.zip_code}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.language}</td>
                    <td className={classNames(classNamesTD)}>{subscriber.member_rating}</td>
                    <td className={classNames(classNamesTD,"w-full")}>{UserLocalTime(subscriber.created_at)}</td>
                 
                  </tr>
                )) : 
                  <>
                    <tr className='animate-pulse font-bold text-lg ml-10 mt-10 text-white h-100 w-full '><td>Loading</td></tr>
                  </>}
              </tbody>
            </table>
  )
}