import Logo from "../../assets/icon.png"
import Bg from "../../assets/forgottenpassword.webp"
import { useState } from "react";
import { supabase } from "../../helpers/supabase";
import { Link } from "react-router-dom";

export default function ForgottenPassword() {

  const [email,setEmail] : any = useState("");
  const [isSubmit,setIsSubmit] : any = useState(false);

  let submit = async (e:any) => {
    e.preventDefault()
    if (!isSubmit){

      setIsSubmit(true)

      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: process.env.REACT_APP_REDIRECT_URL+"/reset-password"
      })
    }
  }

  return (
    <>
    
      <div className="flex min-h-[100vh] bg-gray-900 flex-1">
        <div className="flex basis-full lg:basis-1/2 lg:justify-center lg:items-center flex-col justify-center">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-14 w-auto"
                src={Logo}
                alt="Your Company"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-white">
                Forgotten password
              </h2>
              <p className="mt-2 text-sm font-bold leading-6 text-gray-300">
                Not a member?{' '}
                <Link to="/signup" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Sign up
                </Link>
              </p>
            </div>

            <div className="mt-10">
              <div>
                <form onSubmit={submit} method="POST" className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                    </div>
                  </div>
  
  
                  <div>
                    <button
                      type="submit"
                      
                      className={`${isSubmit && "cursor-default"} flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                      
                    >
                      {!isSubmit ? "Get reset link" : "Check your inbox"}
                    </button>
                  </div>
                </form>
              </div>
  
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={Bg}
            alt=""
          />
        </div>
      </div>
    </>
  )
}
  