import { useContext, useEffect, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { TeamContext } from "../../helpers/TeamContext";


export function TagSelectSnippet({setTagsValue,className=null,simple=false,valueArray=null,value,onChange}:{setTagsValue?:any,className?:any,simple?:boolean,valueArray?:any,value?:string,onChange?:any}){

  const {teamId} = useContext(TeamContext)

  // tags and tag management
  const [tags, setTags] = useState([{ id: 1, value: '' }]);
  const [existingTags, setExistingTags] = useState([]);
  const [existingFilteredTags, setExistingFilteredTags] = useState([]);
  const [activeTagField, setActiveTagField] : any = useState(null);


  useEffect(() => {
    async function getTags(){
      let {data} :any= await supabase.from("customer_tags").select("*").filter("team_id","eq",teamId);
      setExistingTags(data)
      setExistingFilteredTags(data)

      // upon edit a subscriber --> setTags to subscribers list
      // used in editsubscriber to add the values
      if (valueArray !== null){
        let arr = []
        console.log(valueArray)

        for (let i = 0; i < valueArray.length; i++){
          let value = data.filter((e:any) => e.id === valueArray[i])[0].tag
          arr.push({id: i, value: value })
        }

        if (arr.length >= 1){
          setTags(arr)
          setTagsValue(arr)
        }
      }
    }
    getTags()
  },[])

  const getFilteredTags = (tagQuery:any) => {
    setExistingFilteredTags(existingTags.filter((tag:any) => tag.tag.includes(tagQuery)))
  }

  // Function to add a new tag
  const addNewTag = () => {
    const newTag = { id: tags.length + 1, value: '' };
    setTagsValue && setTagsValue([...tags, newTag]) // set parent tag
    setTags([...tags, newTag]);
  };

  // Function to handle change in select option for each tag
  const handleTagChange = (id:any, newValue:any) => {


    let handleTagChange = tags.map(tag => tag.id === id ? { ...tag, value: newValue } : tag)
    
    setTagsValue && setTagsValue(handleTagChange) // set parent tag
    setTags(handleTagChange)

    // used to get the ID which is used in customer segment creation
    let findTagIdFromName : any = existingTags.filter((e:any) => e.tag === newValue); 
    onChange && onChange(handleTagChange[0].value+":"+findTagIdFromName[0].id)
  };

  // Function to remove a tag
  const removeTag = (id:any) => {
    let removeTag = tags.filter(tag => tag.id !== id)
    setTagsValue && setTagsValue(removeTag) // set parent tag
    setTags(removeTag);
  };


  return (
    <>
    {simple===false && <label className="block text-sm font-medium leading-6 text-white">Tags</label>}
    {tags.map((tag, index) => (
      <div key={tag.id} className={!className ? `mt-2 items-center gap-2 flex relative` : 'relative'}>
        <input
          type="text"
          id={`tag-${tag.id}`}
          name={`tag-${tag.id}`}
          autoComplete="tag-name"
          readOnly={simple?true:false}
          className={className?className:`block w-96 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black`}
          value={value?value:tag.value}
          onChange={(e) => {
            handleTagChange(tag.id, e.target.value.toLowerCase());
            getFilteredTags(e.target.value)
          }}
          onFocus={() => {setActiveTagField(tag.id)}}
          onBlur={() => {setTimeout(()=>setActiveTagField(null),250)}}
        />
        <div className={`${existingFilteredTags.length >= 1 && activeTagField===tag.id ? 'flex' : 'hidden'} z-10 absolute bg-gray-700 border gap-1 flex-wrap border-indigo-500 top-[110%] rounded-md w-96 p-2`}>
          <p className='basis-full w-full font-bold text-sm mb-1 text-white'>Existing tags</p>
          {
          //getFilteredTags
          existingFilteredTags.map((e:any,i:number) => {
           return <p key={i} onClick={() => {
            handleTagChange(tag.id,e.tag);
            setTimeout(() => setExistingFilteredTags(existingTags),200)
          }} className='border bg-indigo-500 px-1 text-white font-bold text-sm rounded-md cursor-pointer hover:bg-indigo-400'>{e.tag}</p>
          })}
          </div>
        {(tags.length > 1 && simple===false) && (
          <div onClick={() => removeTag(tag.id)} className='cursor-pointer basis-15'>
            <p className="text-sm font-semibold leading-6 text-red-500">- Remove</p>
          </div>
        )}
      </div>
    ))}

    {simple===false && <div onClick={addNewTag} className='cursor-pointer basis-15 mt-3'>
    <p className="text-sm font-semibold leading-6 text-white">+ Add tag</p>
    </div>}
    </>
  )
}