import React, { createContext, useState, useEffect, ReactNode } from 'react';

// Define the shape of your context state
interface AuthContextState {
  token: any | null;
  setToken: (token: any | null) => void;
}

// Create the context with a default value
export const AuthContext = createContext<AuthContextState>({
  token: null,
  setToken: () => {},
});

// Typing for the props of AuthProvider
interface AuthProviderProps {
  children: ReactNode; // This will accept any valid React node
}

// Create a provider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<any | null>(localStorage.getItem('sb-jcrcgikutgmjucxajnng-auth-token'));

  useEffect(() => {
    // Sync with local storage upon initial load
    const jwtToken = localStorage.getItem('sb-jcrcgikutgmjucxajnng-auth-token');
    if (jwtToken) {
      setToken(jwtToken);
    }
  }, []);

  useEffect(() => {
    // Update local storage whenever the token changes
    if (token) {
      localStorage.setItem('sb-jcrcgikutgmjucxajnng-auth-token', token);
    } else {
      localStorage.removeItem('sb-jcrcgikutgmjucxajnng-auth-token');
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};
