import Logo from "../../assets/icon.png"
import Bg from "../../assets/signup.webp"

export default function VerifyAccount() {

  return (
    <>
    
      <div className="flex min-h-[100vh] bg-gray-900 flex-1">
        <div className="flex basis-full lg:basis-1/2 lg:justify-center lg:items-center flex-col justify-center">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-14 w-auto"
                src={Logo}
                alt="Your Company"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-white">
              Verify your email to get started.
              </h2>
              <p className="mt-2 text-sm font-bold leading-6 text-gray-300">
              We've sent you a verification email with a link you need to click to verify your email.    
              </p>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={Bg}
            alt=""
          />
        </div>
      </div>
    </>
  )
}
  