
import Header from '../../../components/layout/Header'
import { useContext, useState } from 'react'
import { ButtonActions } from '../../../components/layout/ButtonActions'
import { SUBSCRIBER } from '../subscribers/subscriberEnum'
import { Button } from '../../../components/input/Button'

import { TagSelectSnippet } from '../../../components/input/TagSelectSnippet'
import { SelectLanguage } from '../../../components/input/SelectLanguage'
import { SelectCountry } from '../../../components/input/SelectCountry'
import { supabase } from '../../../helpers/supabase'
import { TeamContext } from '../../../helpers/TeamContext'
import { useNavigate } from 'react-router-dom'
import { axios } from '../../../helpers/axios'
import qs from "qs"

const conditionOperators = ['AND', 'OR'];
const comparisonOperatorsAll = ['EQUAL', 'NOT EQUAL', 'INCLUDES', 'NOT INCLUDES'];
const comparisonOperatorsLimited = ['EQUAL', 'NOT EQUAL'];


export default function NewCustomerSegments() {

  const navigate = useNavigate()
  const {teamId} = useContext(TeamContext)
  
  
  const [tag,setTag] = useState()
  const [queryState,setQueryState] = useState()

  const [segmentName, setSegmentName]:any = useState('');
  const [description, setDescription]:any = useState('');
  const [conditions, setConditions]:any = useState([]);

 

  const handleAddCondition = () => {
    const newCondition = {
      conditionOperator: 'AND', // default value
      field: Object.keys(SUBSCRIBER)[0], // default to the first field
      comparisonOperator: 'EQUAL', // default value
      value: '' // default empty value
    };
    setConditions([...conditions, newCondition]);
  };

  const handleConditionChange = (index:number, part:any, value:string) => {
    const updatedConditions = conditions.map((condition:any, i:number) => {
      if (i === index) {
        
        // revert if user is trying to switch to TAGS | INTERESTS to prevent non-working query
        if (!getAllowedComparisonOperators(value).includes(condition.comparisonOperator)){
          alert("NOTICE: Only " + comparisonOperatorsLimited.join(", ") + " is allowed for this field. Please change comparison to one of these before switching.")
          return condition;
        }

        // when a user is changing away from a tag to another field we're going to set the field value to "" since it's currently "hidden" as TAG:ID
        if (condition.field === SUBSCRIBER.tags && part === 'field'){
          condition.value = ""
        }

        // set the new operator values
        return { ...condition, [part]: value };
      }
      return condition;
    });
    
    handleSubmitQuery()
    setConditions(updatedConditions);
  };

  const handleRemoveCondition = (index:number) => {
    const updatedConditions = conditions.filter((_:any, i:number) => i !== index);
    setConditions(updatedConditions);
  };

  const handleSubmitQuery = () => {
    const reverseSubscriberLookup: { [key: string]: keyof typeof SUBSCRIBER } = {};
    Object.entries(SUBSCRIBER).forEach(([key, value]) => {
      reverseSubscriberLookup[value] = key as keyof typeof SUBSCRIBER;
    });
  
    const sqlQuery = conditions.reduce((acc: string, condition: any, index: number) => {
      // Use the reverse lookup to get the enum key from the display value
      const enumKey = reverseSubscriberLookup[condition.field];
      if (!enumKey) {
        console.error('Invalid field:', condition.field);
        return acc; // Skip this condition if the field is invalid
      }

      let conditionValue = condition.value

      if (enumKey === SUBSCRIBER.tags){
        conditionValue = conditionValue.split(":").pop()
      }
  
      let conditionString = `${enumKey} `;
      switch (condition.comparisonOperator) {
        case 'EQUAL':
          conditionString += `= '${conditionValue}'`;
          break;
        case 'NOT EQUAL':
          conditionString += `!= '${conditionValue}'`;
          break;
        case 'INCLUDES':
          conditionString += `LIKE '%${conditionValue}%'`;
          break;
        case 'NOT INCLUDES':
          conditionString += `NOT LIKE '%${conditionValue}%'`;
          break;
        default:
          break;
      }
      if (index > 0) {
        conditionString = `${condition.conditionOperator} ${conditionString}`;
      }
      return `${acc} ${conditionString}`;
    }, 'WHERE ');
  
    setQueryState(sqlQuery)
    return sqlQuery;
  };

  // based on the selected conditional logic data field --> return specific input field types or selects
  const RenderInputField = (value:any,fieldType:string,index:number) => {

    let returnField : any;

    if (fieldType===SUBSCRIBER.tags){
   
      // onchange on tagselectsnippet is made for this page --> it returns NAME:TABLE_ID so we can replace the value with ID here
      returnField = <TagSelectSnippet   
                          simple={true} 
                          value={value.split(":").shift()}
                          className='bg-gray-950 w-80 text-white' 
                          onChange={(e:any) => handleConditionChange(index, 'value', e)}
                          />

    } else if (fieldType===SUBSCRIBER.country){

      returnField = <SelectCountry 
                        className='bg-gray-950 w-80 text-white'
                        defaultValue={value}
                        onChange={(e:any) => handleConditionChange(index, 'value', e.target.value)}/>
      
    } else if (fieldType===SUBSCRIBER.language){
      
      returnField = <SelectLanguage 
                        className='bg-gray-950 w-80 text-white'
                        defaultValue={value}
                        onChange={(e:any) => handleConditionChange(index, 'value', e.target.value)}/>
      

    } else if (fieldType===SUBSCRIBER.birthday){

      returnField = <input
      className='bg-gray-950 text-white w-80'
      type="date"
      value={value}
      onChange={(e) => handleConditionChange(index, 'value', e.target.value)}
    />

    } else if (fieldType===SUBSCRIBER.member_rating){

      returnField = (<select
      className='bg-gray-950 text-white w-80'
      value={value}
      onChange={(e) => handleConditionChange(index, 'value', e.target.value)}>
        <option value="0">0 - have opted out</option>
        <option value="1">1 - not engaging, opening emails</option>
        <option value="2">2 - default</option>
        <option value="3">3 - opens some emails</option>
        <option value="4">4 - opens most emails and interacts</option>
        <option value="5">5 - opens all emails and interacts</option>
      </select>)
    
    } else if (fieldType===SUBSCRIBER.optin_time && 0){
      // this is the optin time. Need something more specific?
    } else {
      returnField = <input
      className='bg-gray-950 text-white w-80'
      type="text"
      value={value}
      onChange={(e) => handleConditionChange(index, 'value', e.target.value)}
    />
    }
    return returnField
  }

  const getAllowedComparisonOperators = (field:any) => {
    let limitedAllowed = [SUBSCRIBER.interests,SUBSCRIBER.tags]
    let allowedOperations = comparisonOperatorsAll

    // return operation that only allow simple comparison and not %LIKE%
    if (limitedAllowed.includes(field)){
      allowedOperations = comparisonOperatorsLimited
    } 
    return allowedOperations
  }

  const submit = () => {
    let query = handleSubmitQuery()
    if (segmentName && query){
      // if it's a tag we want to replace the value with the ID
      
      // segmentName, description, segmentation_query
      try {
        supabase.from("customer_segments").insert({
          team_id: teamId,
          name: segmentName,
          description: description,
          segmentation_query: query,
          frontend_segmentation_query: conditions
        })
        .select()
        .single()
        .then((res:any) => {
          if (res.data){
            let segment = res.data
            axios.post(process.env.REACT_APP_API+"/v1/customersegments/init",qs.stringify({customersegment_id:segment.id,teamid:teamId}))
          }
        
          navigate("/customer-segments")
  
        })
      } catch (e){
        alert(JSON.stringify(e))
      }
      // insert
    }
  }

  return (
    <>
    <Header title="New Customer segment"/>

    <main className="mt-10 mx-auto">
      <div className="border-b border-gray-800 mt-10 pb-10">
        <div className="gap-x-6 gap-y-8 text-gray">

        <div className="w-1/3 sm:px-6 lg:px-8">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
              Segment name
            </label>
            <div className="mt-2">
              <input
                type="email"
                autoComplete="email"
                value={segmentName}
                onChange={(e) => setSegmentName(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="w-1/3 sm:px-6 lg:px-8 mt-5">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
              Description
            </label>
            <div className="mt-2">
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              ></textarea>
            </div>
          </div>

          <div className='mt-10 text-gray border-t border-gray-800 pt-10 sm:px-6 lg:px-8'>

            <p className='text-white font-xl font-bold pb-5'>Conditional logic</p>

            
            {/* Map through conditions to display them, or display a message if there are none */}
            {conditions.length > 0 ? (
            conditions.map((condition:any, index:any) => (
                <div key={index} className="flex">
                  <select
                    className='bg-gray-950 text-white'
                    value={condition.conditionOperator}
                    onChange={(e) => handleConditionChange(index, 'conditionOperator', e.target.value)}
                  >
                    {conditionOperators.map((op) => (
                      <option key={op} value={op}>{op}</option>
                    ))}
                  </select>

                  <select
                    className='bg-gray-950 text-white'
                    value={condition.field}
                    onChange={(e) => handleConditionChange(index, 'field', e.target.value)}
                  >
                    {Object.entries(SUBSCRIBER).map(([key, value]) => (
                      <option key={key} value={value}>{value.toUpperCase()}</option>
                    ))}
                  </select>

                  <select
                    className='bg-gray-950 text-white w-56'
                    value={condition.comparisonOperator}
                    onChange={(e) => handleConditionChange(index, 'comparisonOperator', e.target.value)}
                  >
                    {getAllowedComparisonOperators(condition.field).map((op:any) => (
                      <option key={op} value={op}>{op}</option>
                    ))}
                  </select>

                  {/** conditional input type rendering based on FIELD VALUE selected by user */}
                  {RenderInputField(condition.value,condition.field,index)}
                  
                  <button className='text-white font-bold px-3' onClick={() => handleRemoveCondition(index)}>Remove</button>
                </div>
              ))
              ) : (
                <p className="text-white">No conditions added. Click "Add Condition" to start.</p>
              )}


              {/* Always show the add condition button */}
              <div className='mt-5'>
                <Button title="Add condition" onClick={handleAddCondition}/>
              </div>
          
          </div>
        </div>
      </div>

      <div className=' sm:px-6 lg:px-8'>
        <ButtonActions cancelLink='/customer-segments' onClickSubmit={()=>submit()}/>
      </div>

    </main>
    </>
  )
}
