// useAutoRefreshSession.js
import { useEffect } from 'react';
import { supabase } from './supabase'; // Import the initialized Supabase client

export function useAutoRefreshSession() {
  useEffect(() => {
    const session : any = supabase.auth.getSession()

    const handleRefreshToken = async () => {

      const timeToExpiration = session.expires_at * 1000 - new Date().getTime();
      const fiveMinutes = 5 * 60 * 1000;
    
      // If the session expires within the next 5 minutes, refresh it
      if (timeToExpiration < fiveMinutes) {
        const { error } = await supabase.auth.refreshSession();
        if (error) {
          console.error('Error refreshing auth session', error);
        }
      }
    };

    // Refresh the token every 10 minutes
    //const interval = setInterval(handleRefreshToken, 10 * 60 * 1000);
    const interval = setInterval(handleRefreshToken, 10 * 60 * 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);


  const { data } = supabase.auth.onAuthStateChange((event, session) => {
  //  console.log(event, session)

    if (event === 'INITIAL_SESSION') {

      // handle initial session
    } else if (event === 'SIGNED_IN') {

      // handle sign in event
    } else if (event === 'SIGNED_OUT') {
      // handle sign out event
    } else if (event === 'PASSWORD_RECOVERY') {
      // handle password recovery event
    } else if (event === 'TOKEN_REFRESHED') {

      // handle token refreshed event
    } else if (event === 'USER_UPDATED') {
      // handle user updated event
    }
  })

// call unsubscribe to remove the callback
//data.subscription.unsubscribe()
}
