
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../../../helpers/supabase'
import {axios} from '../../../helpers/axios'

 

const secondaryNavigation = [
  { name: 'Account', href: '#', current: true },/*
  { name: 'Billing', href: '#', current: false },*//*
  { name: 'Integrations', href: '#', current: false },*/
]
export default function ViewTeam() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const navigate = useNavigate()
 
  const [apiKey,setApiKey] = useState("")
  const [teamId,setTeamId] = useState("")
  const [teamName,setTeamName] = useState("")
  const [domain,setDomain] = useState("")
  const [hex,setHex] = useState("")
  const [description,setDescription] = useState("")

  const [dkim,setDkim] :any = useState("")

  const records = [
    { name: domain, height:8, type: 'TXT', value: 'v=spf1 include:mail.notificationharbor.com -all' },
    { name: "notificationharbor._domainkey."+domain, height:32, type: 'TXT', value: dkim },
  ];
  
  useEffect(() => {
    // get user data
    let getSupabase = async () => {

      const {data:{user}} = await supabase.auth.getUser();
      if (user?.id){
        const {data} = await supabase.from("teams").select("*").filter("owner","eq",user?.id)

        if (data!=null){
          if (data[0]){

            setApiKey(data[0].api_key)
            setTeamId(data[0].team_id)
            setTeamName(data[0].team_name)
            setDomain(data[0].domain)
            setHex(data[0].hex_color)
            setDescription(data[0].description)

              // print domain and DKIM for user

    if (localStorage.getItem("dkim")){
      setDkimFunc(localStorage.getItem("dkim"))
    } else {
      // get dkim

      axios.get(process.env.REACT_APP_API+`/v1/teams/${data[0].team_id}/dkim`).then((res) => {
        localStorage.setItem("dkim",res.data.dkim.dkimRecord)
        setDkimFunc(res.data.dkim.dkimRecord)
      }).catch((e) => {

      })
    //  setDkimFunc(localStorage.getItem("dkim"))

    }


          }
        }

      }

    }
    getSupabase()

 
    
  },[])

  let setDkimFunc = (dkim:any) => {

    let dkimstring : any = dkim
    let raw = dkimstring?.split("v=DKIM1;")[1]
    raw = raw?.split('" )')[0]
    raw = '"v=DKIM1; '+raw+'"'
    raw = raw.replace(/(\r\n|\n|\r)/gm, "");
    raw = raw.replace(/\s/g,'')


    setDkim(raw)

    let domain = dkimstring.split(" ")[dkimstring.split(" ").length - 1]
    setDomain(domain)

  }

  return (
   
   

    <main className="  py-5 px-5 lg:py-12 lg:px-12">
   

    <div className="mx-auto w-full lg:w-2/3 ">
      <div className="space-y-12">
        <div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">My team</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            This information will not be displayed publicly.
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            
          <div className="sm:col-span-4">
              <label htmlFor="company" className="block text-sm font-medium leading-6 text-white">
                Company / Team name *
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="company"
                  id="company"
                  readOnly
                  value={teamName}
                  autoComplete="company"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-white">
                Domain *
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  value={domain}
                  autoComplete="given-name"
                  readOnly
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="color" className="block text-sm font-medium leading-6 text-white">
                Company theme color
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="color"
                  readOnly
                  value={hex}
                  placeholder='HEX or RGB color code for theme color'
                  id="color"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-white">
                About
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  readOnly
                  value={description}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-400">Write a few sentences about what your company does if it's not clear on your website.</p>
            </div>
 {/*
            <div className="col-span-full">
              <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-white">
                Logo
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
                <div className="text-center">
                  <PhotoIcon className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
                  <div className="mt-4 flex text-sm leading-6 text-gray-400">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500"
                    >
                      <span>Upload a file</span>
                      <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
                </div>
              </div>

            </div>*/}
          </div>
        </div>

        {/*<div className="border-b border-white/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-white">Personal Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">Use a permanent address where you can receive mail.</p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-white">
                First name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-white">
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
                Country
              </label>
              <div className="mt-2">
                <select
                  id="country"
                  name="country"
                  autoComplete="country-name"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                >
                  <option>United States</option>
                  <option>Canada</option>
                  <option>Mexico</option>
                </select>
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-white">
                Street address
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="street-address"
                  id="street-address"
                  autoComplete="street-address"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2 sm:col-start-1">
              <label htmlFor="city" className="block text-sm font-medium leading-6 text-white">
                City
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="region" className="block text-sm font-medium leading-6 text-white">
                State / Province
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="region"
                  id="region"
                  autoComplete="address-level1"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-white">
                ZIP / Postal code
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="postal-code"
                  id="postal-code"
                  autoComplete="postal-code"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>*/}

      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <p className='text-white'>settings is currently readonly!</p>
        {/*<button
          type="submit"
          className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          Save
        </button>*/}
      </div>
    </div>




    <div className='mt-10 border-t border-gray-700 pt-5 lg:w-2/3 mx-auto'>
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-0">
            <h2 className="text-base font-semibold leading-7 text-white">DNS Configuration</h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              Please add the following records to your DNS-zone. Until added you can still send emails but they will be sent from hello@notificationharbor.com
            </p>
            

            <div className="mt-5 w-full text-white text-sm">
                <div className="flex border-b border-gray-500 bg-gray-800 p-4">
                    <div className="w-4/12 font-bold">Record</div>
                    <div className="w-1/12 font-bold">Type</div>
                    <div className="w-7/12 font-bold">Value</div>
                </div>
                {records.map((record, index) => (
                    <div key={index} className="flex border-b border-gray-500 p-4">
                        <div className="w-5/12">{record.name}</div>
                        <div className="w-1/12">{record.type}</div>
                        <div className="w-6/12"><textarea className={`bg-gray-800 w-full rounded-sm text-sm p-1 h-${record.height}`} readOnly value={record.value}></textarea></div>
                    </div>
                ))}
            </div>

          </div> 
        </div>
   
      </div> 


      <div className='mt-10 border-t border-gray-700 pt-5 lg:w-2/3 pb-10 mx-auto'>
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-5">
            <h2 className="text-base font-semibold leading-7 text-white">API key</h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
                API key for managing subscribers, tags and transactional API calls
            </p>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            
          <div className="sm:col-span-4">
              <label htmlFor="company" className="block text-sm font-medium leading-6 text-white">
                API key
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="company"
                  id="company"
                  readOnly
                  value={apiKey}
                  autoComplete="company"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            </div>
        </div>
        </div>
      </div>

    </main>
  
    
      
 
  )
}
