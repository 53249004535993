
import { Fragment, useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'
import { supabase } from '../../../helpers/supabase'
import { useNavigate } from 'react-router-dom'
import {axios} from '../../../helpers/axios'
import qs from "qs";


import Logo from "../../../assets/icon.png"

const stepsVar = [
  { id: '01', name: 'Personal account', status: 'complete' },
  { id: '02', name: 'Team account', status: 'current' },
  { id: '03', name: 'Domain verification (optional)',  status: 'upcoming' },
]
 
 
export default function CreateTeam() {

  const navigate = useNavigate()

  const [steps,setSteps] = useState(stepsVar)

  const [teamName,setTeamName] = useState("")
  const [domain,setDomain] = useState("")
  const [hex,setHex] = useState("")
  const [description,setDescription] = useState("")


  const [hasSubmit,setHasSubmit] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("pendingDKIMVerification")){
      navigate("/create-team/verify")
    }
  },[])

  // submit team information
  let submit = async (e:any) =>{
    e.preventDefault()

    // prevent rage clicking
    if (hasSubmit){
      return;
    }
 

    // verify required fields
    if ((!teamName || !domain)){
      alert("Missing required fields")
      return;
    }

    // check that hex is valid
    if (hex){
      if (hex.split("").length !== 7){
        alert("Hexcode invalid")
        return;
      }
      if (hex.split("")[0]!=="#"){
        alert("Hexcode invalid")
        return;
      }
    }

    // verify domain is valid
    if (domain.includes("http://") || domain.includes("https://") || domain.includes("www.") || domain.split(".").length === 1) {
        alert("Invalid domain. Please provide a root domain. Domains can't contain http(s)://(www)");
        return;
    }

    // create team
    setHasSubmit(true)

    // get the user for user id
    const {data: {user}} = await supabase.auth.getUser()

    if (!user){
      setHasSubmit(false)
      alert("cant find user");
      return;
    }

    const genAPIKey = () => {
      //create a base-36 string that contains 30 chars in a-z,0-9
      return [...Array(30)]
        .map((e) => ((Math.random() * 36) | 0).toString(36))
        .join('');
    };


    const {data, error} = await supabase.from("teams").insert({
      owner: user?.id,
      team_name:teamName,
      domain:domain,
      hex_color:hex,
      description:description,
      api_key: genAPIKey()
    }).select()
 

    // if account is created --> create DKIM in network
    if (data){

      axios.post(process.env.REACT_APP_API+"/v1/teams",qs.stringify({"domain":domain})).then((res:any) => {

        localStorage.setItem("dkim",res.data.dkim) // set DKIM as localstorage to be displayed on the next onboarding page
        localStorage.setItem("pendingDKIMVerification","true") // used to keep user on verify page until the buttons are pressed there

        localStorage.setItem("teams",JSON.stringify(data)); // all teams
        localStorage.setItem("activeTeam",JSON.stringify(data)); // active team (currently the only one)

        //  next onboarding setp
        navigate("/create-team/verify")

      }).catch((e) => {
        if (e.response?.data?.error?.message){
          alert(e.response.data.error.message)
        } else {
          alert(e)
        }
      })

    } else if (error){
      setHasSubmit(false)
      alert(error.message)
    } else {
      setHasSubmit(false)
      alert("Unknown error")
    }



  }
   
  return (
   
    <div className='mx-10'>
     <nav aria-label="Progress" className='lg:w-1/2 mx-auto w-full'>
      <div className='mt-10'>
        <img alt="Logo" className='h-12' src={Logo}/>
      </div>
      <ol className="mt-5 divide-y divide-gray-300 rounded-md border border-gray-500 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <div className="cursor-default group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600  ">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500">{step.name}</span>
                </span>
              </div>
            ) : step.status === 'current' ? (
              <div className="cursor-default flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
              </div>
            ) : (
              <div className="cursor-default group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-500 ">
                    <span className="text-gray-500 ">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 ">{step.name}</span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-500"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>

    <main className="mx-auto w-full lg:w-1/2 py-5 lg:pt-10">

 
        <form onSubmit={submit}>
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-white">My team</h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              Let's get you setup by creating your first team!
            </p>
  
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              
            <div className="sm:col-span-4">
                <label htmlFor="company" className="block text-sm font-medium leading-6 text-white">
                  Company / Team name *
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    required
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    autoComplete="company"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div className="sm:col-span-4">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-white">
                  Root domain (domain without https://www. or http://www.) *
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="domain"
                    id="domain"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
  
              <div className="sm:col-span-4">
                <label htmlFor="color" className="block text-sm font-medium leading-6 text-white">
                  Company theme color in HEX (fx. #FF0000)
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="color"
                    placeholder='HEX format theme color'
                    id="color"
                    value={hex}
                    onChange={(e) => setHex(e.target.value)}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-white">
                  About
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-400">In one sentence: What does your company do or sell?</p>
              </div>
   
              {/*<div className="col-span-full">
                <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-white">
                  Logo
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10">
                  <div className="text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-500" aria-hidden="true" />
                    <div className="mt-4 flex text-sm leading-6 text-gray-400">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>
  
              </div>*/}
            </div>
          </div>
   
        </div>
  
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            disabled={hasSubmit ? true : false}
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            {!hasSubmit ? "Save" : "Saving"}
          </button>
        </div>
      </form>  



    </main>
  
    
      
    </div>

  )
}
