import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { generateContext, getSendTypeCountFunction, SENDTYPES, SIDEBARNAVTYPE } from "../../helpers/email/helper";
import { TeamContext } from "../../helpers/TeamContext";
import { supabase } from "../../helpers/supabase";
import { Hash } from "crypto";


export default function Sidebar({
    campaignName,
    setCampaignName,
    intent,
    setIntent,
    questionContextIntent,
    setQuestionContextIntent,
    canGenerateContext,
    sendEmailNow,
    setSendEmailNow,
    sendType,
    setSendNowCountReceivers,
    setSendType,
    sendNowCountReceivers,
    singleEmailReceiver,
    customerSegment,
    setCustomerSegment,
    setSingleEmailReceiver,
    sendEmailNowLoader,
    setSampleGeneratedEmailModel,
    setSidebarNavigation,
    sidebarNavigation
  }:{
    campaignName:string,
    setCampaignName:any,
    intent:string,
    setIntent:any,
    questionContextIntent:string,
    setQuestionContextIntent:any,
    canGenerateContext:boolean
    sendEmailNow:boolean,
    setSendEmailNow:any,
    sendType:string,
    setSendNowCountReceivers:any,
    setSendType:any,
    sendNowCountReceivers:any,
    singleEmailReceiver:string,
    setSingleEmailReceiver:any,
    customerSegment:any,
    setCustomerSegment:any,
    sendEmailNowLoader:boolean,
    setSampleGeneratedEmailModel:any,
    setSidebarNavigation:any
    sidebarNavigation:SIDEBARNAVTYPE
  }){    
    
    return (
        <div className='basis-1/3 text-white'>

        <div className="flex text-slate-300 text-sm justify-start gap-3 px-8 items-center mb-5 border-b border-gray-800 py-5">
          <div className={`cursor-pointer font-semibold ${sidebarNavigation===SIDEBARNAVTYPE.default && "text-indigo-500"}`} onClick={() => setSidebarNavigation(SIDEBARNAVTYPE.default)}><p>Home</p></div>
          <div className={`cursor-pointer font-semibold ${sidebarNavigation===SIDEBARNAVTYPE.html && "text-indigo-500"}`} onClick={() => setSidebarNavigation(SIDEBARNAVTYPE.html)}><p>Edit HTML</p></div>
        </div>

        <div className='p-8 pt-0'>

          <h3 className='font-bold text-xl'>Context</h3>
          <p className="text-sm text-gray-400">Email models cannot be edited. Example email model based on your intent is shown in the right area.</p>

          <EmailSetup 
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          intent={intent}
          setIntent={setIntent}
          questionContextIntent={questionContextIntent}
          setQuestionContextIntent={setQuestionContextIntent}
          canGenerateContext={canGenerateContext}
          setSampleGeneratedEmailModel={setSampleGeneratedEmailModel}
          />


          <SendEmailToSubscribers
          sendEmailNow={sendEmailNow}
          setSendEmailNow={setSendEmailNow}
          sendType={sendType}
          setSendType={setSendType}
          singleEmailReceiver={singleEmailReceiver}
          customerSegment={customerSegment}
          setCustomerSegment={setCustomerSegment}
          setSingleEmailReceiver={setSingleEmailReceiver}
          setSendNowCountReceivers={setSendNowCountReceivers}
          />

          <div className="mt-10 pt-5 border-t justify-between items-center flex border-gray-800">
            <p className="text-sm color-gray-500 italic">{sendEmailNow ? "Email will be sent to "+sendNowCountReceivers+" subscriber(s)" : "" }</p>
            <div className="  flex  items-center justify-end">
              <button
                type="submit"
                className="flex rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >

                  {sendEmailNowLoader && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>}
                {sendEmailNow ? (!sendEmailNowLoader ? "Send email" : "Generating email") : "Save email" }
              </button>
            </div>
          </div>


        </div>
        </div>
)
}

export function EmailSetup({
    campaignName,
    setCampaignName,
    intent,
    setIntent,
    questionContextIntent,
    setQuestionContextIntent,
    canGenerateContext,
    setSampleGeneratedEmailModel
  }:{
    campaignName:string,
    setCampaignName:any,
    intent:string,
    setIntent:any,
    questionContextIntent:string,
    setQuestionContextIntent:any,
    canGenerateContext:boolean,
    setSampleGeneratedEmailModel:any
  }){

    const {hexColor,domain} = useContext(TeamContext)
    const [generatingContext,setGeneratingContext] = useState(false)

    return (<div>

        <div className="sm:col-span-3 mt-5">
          <label htmlFor="name" className="block text-sm font-medium leading-6 text-white">
            Campaign name
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="name"
              id="name"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              autoComplete="off"
              className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        

        <div className="sm:col-span-3 mt-5">
          <label htmlFor="intent" className="flex relative text-sm font-medium leading-6 text-white">
            Context intent           
            
          <QuestionMarkCircleIcon 
            onMouseEnter={() => setQuestionContextIntent(true)} 
            onMouseLeave={() => setQuestionContextIntent(false)} 
            className="w-5 h-5 ml-3 cursor-pointer"/>   
          </label>
        
          <div className="mt-2">
            {canGenerateContext?
            
            <textarea
              name="intent"
              id="intent"
              value={intent}
              onChange={(e) => setIntent(e.target.value)}
              className="block w-full h-28 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />:
            
            <textarea
              name="intent"
              id="intent"
              value={intent}
              className="block w-full h-28 rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              readOnly
            />}
          </div>
          

          <div>
            <div className={`${questionContextIntent ? "display" : "hidden"}`}>
              <p className="text-sm flex text-gray-400">
                Context intent is where you specify what the email should be about and what content it should or must include. 
                All content from links is also included to create the email and email template. You can use prompt engineering here.<br/> 
                Sample context intent: "New product sales for new customers in Copenhagen https://mycompany.com/my-sales-product" 
              </p>
            </div>
          </div>

          {canGenerateContext && 
            <div className="mt-8 flex justify-end">
                <button
                  onClick={(e) => {
                    if (!generatingContext) { 
                        setGeneratingContext(true) 
                        generateContext(e,intent,hexColor,domain).then(
                          (res:any) => { 
                            setGeneratingContext(false) 
                            console.log(res)
                            setSampleGeneratedEmailModel(res)
                          }
                      ).catch(e => {
                        setGeneratingContext(false) 
                        alert(e.message?e.message:e)
                      })
                    }
                  }}
                  type="submit"
                  className="flex rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  {generatingContext ? 
                    <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <p>Generating context</p>
                    </>
                    :
                    <p>Generate context</p>
                    }
                </button>
                
              </div>}

        </div>
      </div>)
}

export function SendEmailToSubscribers({
    sendEmailNow,
    setSendEmailNow,
    sendType,
    setSendType,
    singleEmailReceiver,
    setSingleEmailReceiver,
    setSendNowCountReceivers,
    customerSegment,
    setCustomerSegment
  }:{
    sendEmailNow:boolean,
    setSendEmailNow:any,
    sendType:string,
    setSendType:any,
    singleEmailReceiver:string,
    setSingleEmailReceiver:any,
    setSendNowCountReceivers:any,
    customerSegment:any,
    setCustomerSegment:any
  }){

    const {teamId} = useContext(TeamContext)
    const [customerSegments,setCustomerSegments] : any = useState([])

    useEffect(() => {
      async function prepare(){
        let {data} = await supabase.from("customer_segments").select("*").eq("team_id",teamId).order("created_at",{ascending:false})
        if (data !== null && data.length >= 0){
          setCustomerSegments(data)
        }
      }
      prepare()
    },[])

    return (
        <div className="mt-5 pt-5 border-t border-gray-800">

            <div className="sm:col-span-3 mt-5">
              <label htmlFor="sendNow" className="block text-sm font-medium leading-6 text-white">
                Do you want to send this email now?
                <input
                  onClick={() => setSendEmailNow(!sendEmailNow)}
                  type="checkbox"
                  name="sendNow"
                  id="sendNow"
                  className="ml-2"
                  onChange={()=>sendEmailNow ? true : false}
                  checked={sendEmailNow ? true : false}
                />
              </label>
            </div>


           {sendEmailNow && 
           <>
           <span className="isolate inline-flex rounded-md shadow-sm my-5">
              <button
                onClick={() => { setSendType(SENDTYPES.singleEmail); getSendTypeCountFunction(SENDTYPES.singleEmail,teamId,setSendNowCountReceivers)}}
                type="button"
                className={`
                  ${sendType===SENDTYPES.singleEmail?"bg-indigo-500":"bg-indigo-400"}
                  relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-indigo-600 hover:bg-indigo-600 focus:z-10
                  `}
              >
                Single email
              </button>
              <button
                onClick={() => {setCustomerSegment(""); setSendType(SENDTYPES.customerSegment); getSendTypeCountFunction(SENDTYPES.customerSegment,teamId,setSendNowCountReceivers)}}
                type="button"
                className={`
                ${sendType===SENDTYPES.customerSegment?"bg-indigo-500":"bg-indigo-400"}
                relative inline-flex items-center  px-3 py-2 text-sm font-semibold text-white border-t border-b border-indigo-600 hover:bg-indigo-600 focus:z-10
                `}
              >
                Customer segment
              </button>
              <button
                onClick={() => {setSendType(SENDTYPES.all); getSendTypeCountFunction(SENDTYPES.all,teamId,setSendNowCountReceivers)}}
                type="button"
                className={`
                ${sendType===SENDTYPES.all?"bg-indigo-500":"bg-indigo-400"}
                relative inline-flex items-center rounded-r-md  px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-indigo-600 hover:bg-indigo-600 focus:z-10
                `}  
              >
                All
            </button>
            </span>

            <div className={`${sendType===SENDTYPES.singleEmail?"display":"hidden"}`}>
              <div className="sm:col-span-3 mt-5">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={sendType===SENDTYPES.singleEmail ? singleEmailReceiver : ""}
                    onChange={(e) => setSingleEmailReceiver(e.target.value.toLowerCase())}
                    autoComplete="email"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>


            <div className={`${sendType===SENDTYPES.customerSegment?"display":"hidden"}`}>
               <label htmlFor="customersegment" className="block mt-5 text-sm font-medium leading-6 text-white">
                  Customer segment
                </label>
                <div className="mt-2">
                  <select
                    id="customersegment"
                    name="customersegment"
                    defaultValue={customerSegment}
                    value={customerSegment}
                    onChange={(e) => {
                      setCustomerSegment(e.target.value)
                      getSendTypeCountFunction(SENDTYPES.customerSegment,teamId,setSendNowCountReceivers,e)
                    }}
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                  >
                    <option value="" disabled>Select segment</option>
                    {customerSegments.map((e:any,i:number) => <option key={i} value={"#"+e.id+" "+e.name}>#{e.id} {e.name}</option>)}
                  </select>
                </div>

            </div>

            <div className={`${sendType===SENDTYPES.all?"display":"hidden"}`}>
              <p className='text-white text-sm'>Let's go! You're sending this email to all your subscribers</p>

            </div>
            </>
            }
            

          </div>
    )
}