
import { useContext, useState } from 'react'
import Header from '../../../components/layout/Header'
import { Link, useNavigate } from 'react-router-dom'
import { supabase } from '../../../helpers/supabase'
import { TeamContext } from '../../../helpers/TeamContext'
import { TagSelectSnippet } from '../../../components/input/TagSelectSnippet'
import { InterestsInputSnippet } from '../../../components/input/InterestsInputSnippet'
import { InputWithTitle } from '../../../components/input/InputWithTitle'
import { TitleFieldWrapper } from '../../../components/input/TitleFieldWrapper'
import { SelectCountry } from '../../../components/input/SelectCountry'
import { SelectLanguage } from '../../../components/input/SelectLanguage'
import { ButtonActions } from '../../../components/layout/ButtonActions'

 


 


const secondaryNavigation = [
    { name: 'Single subscriber', href: '/subscribers/new', current: true },
    { name: 'Import subscribers', href: '/subscribers/import', current: false },
  ]
  


export default function NewSubscribers() {

    const navigate = useNavigate();
    const {team} = useContext(TeamContext)

    // tags and tag management
    const [tags, setTags] : any = useState([]);
    const [interests, setInterests] : any = useState([]);

 
    // submit function
    let submit = async (e:any) => {
      e.preventDefault();

      const form = e.target; // This is the form element
      const email = form.elements.namedItem('email').value.toLowerCase();
      const first_name = form.elements.namedItem('first_name').value;
      const last_name = form.elements.namedItem('last_name').value;
      const phone = form.elements.namedItem('phone').value;
      const birthday = form.elements.namedItem('birthday').value;
      const country = form.elements.namedItem('country').value;
      const language = form.elements.namedItem('language').value;
      const city = form.elements.namedItem('city').value;
      const address = form.elements.namedItem('address').value;
      const region = form.elements.namedItem('region').value;
      const zip_code = form.elements.namedItem('zip_code').value;
      

      // tags and interests from state objects
      let interestsArr = interests.length >= 1 ? interests.map((interest:any) => interest.value) : []
      const interestsC = {"interests": interestsArr}

   
      if (!email){
        alert("Email cannot be empty")
        return;
      }

      const teamId = JSON.parse(team)[0].team_id

      if (!teamId){
        alert("Couldn't find team id")
        return;
      }

      // check if email exist in subscriber list
      const {data,error} = await supabase.from("subscribers").select("*").filter("team_id","eq",teamId).filter("email","eq",email)

      if (error){
        alert(error);
        return;
      }
 
      if (data != null && data.length >= 1){
        alert("Email already exists among subscribers");
        return;
      }

      const tagsC = tags.length >= 1 && (tags.map((tag:any) => tag.value));

      // check if tag exist - if not insert
      let tagData = []
      for (let i = 0; tagsC.length > i; i++){

        const { data } = await supabase
        .from('customer_tags')
        .select("*")
        .filter("team_id","eq",teamId)
        .filter("tag","eq",tagsC[i])

        if (data?.length === 0){

          const { data } = await supabase
          .from('customer_tags')
          .insert({
            team_id: teamId,
            tag: tagsC[i].toLowerCase()
          }).select()
          
          if (data){
            tagData.push(data[0].id)
          }

        } else {
          if (data){
            tagData.push(data[0].id)
          }
        }
      }
      // get tag id to insert as reference
      const tagsId = {"tags":tagData}

      // insert subscriber
      await supabase.from("subscribers").insert({
        team_id: teamId,
        email: email,
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        birthday: birthday,
        tags: tagsId,
        interests: interestsC,
        language: language,
        country: country,
        city: city,
        address: address,
        region: region,
        zip_code: zip_code
      }).then((res) => {
        navigate("/subscribers",{state:{newsubscriber:true}})
      })


    }

  return (
    <>
    <Header title="New subscriber"/>
    {/* Secondary navigation */}
    <nav className="flex overflow-x-auto py-4">
        <ul
        className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
        >
        {secondaryNavigation.map((item) => (
            <li key={item.name}>
                <Link to={item.href} className={item.current ? 'text-indigo-600' : ''}>
                {item.name}
                </Link>
            </li>
        ))}
        </ul>
    </nav>

    <main className=" sm:px-6 lg:px-8">


    <form onSubmit={submit}>


    <div className="border-b border-white/10 pb-12 mt-7">
          <h2 className="text-base font-semibold leading-7 text-white">Subscriber information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">Add a single subscriber to the email list.</p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 w-3/5 ">

 

            <div className="sm:col-span-4">
              <InputWithTitle name="email" title="Email address *"/>
            </div>

            <div className="sm:col-start-1 sm:col-span-2">
              <InputWithTitle name="first_name" title="First Name"/>
            </div>

            <div className="sm:col-span-2">
              <InputWithTitle name="last_name" title="Last Name"/>
            </div>


            <div className="col-span-4">
              <label htmlFor="phone" className="block text-sm font-medium leading-6 text-white">
                Phone
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="phone"
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-4">
              <TitleFieldWrapper name="birthday" title='Birthday'>
                <input
                    type="date"
                    name="birthday"
                    id="birthday"
                    autoComplete="birthday"
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
              </TitleFieldWrapper>
            </div>


            <div className="sm:col-span-4">
              <TitleFieldWrapper name="country" title="Country">
                <SelectCountry/>
              </TitleFieldWrapper>
            </div>


            <div className="sm:col-span-4">
              <TitleFieldWrapper name="language" title="Language">
                <SelectLanguage/>
              </TitleFieldWrapper>
            </div>

            <div className="sm:col-span-4 sm:col-start-1">
              <InputWithTitle name="city" title="City"/>
            </div>

            <div className="sm:col-span-4 sm:col-start-1">
              <InputWithTitle name="address" title="Address"/>
            </div>

            <div className="sm:col-span-4 sm:col-start-1">
              <InputWithTitle name="region" title="Region/State/Province"/>
            </div>

            <div className="col-span-4">
              <InputWithTitle name="zip_code" title="Zip code"/>
            </div>


            <div className="sm:col-span-4">
              <TagSelectSnippet setTagsValue={setTags}/>
            </div>

            <div className="sm:col-span-4">
              <InterestsInputSnippet setInterestsValue={setInterests}/>
            </div>
            
          </div>
        </div>


        <ButtonActions cancelLink="/subscribers"/>

    </form>

    </main>
    </>
  )
}
