
export function TitleFieldWrapper({name,title,children}:{name:string,title:string,children:any}){
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-white">
        {title}
      </label>
      <div className="mt-2">
        {children}
      </div>
    </div>
  )
}