
export function UserLocalTime(utcTimestamp:string) {
    // Parse the UTC timestamp to a Date object
    const date = new Date(utcTimestamp);
  
    // Format the date to a local string. You can customize this as needed.
    // For more control over the format, consider using toLocaleDateString and toLocaleTimeString
    const localTime = date.toLocaleString();
  
    return localTime
  }