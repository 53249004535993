
import { Fragment, useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';
import Logo from "../../../assets/icon.png"

 
const stepsVar = [
    { id: '01', name: 'Personal account', status: 'complete' },
    { id: '02', name: 'Team account', status: 'complete' },
    { id: '03', name: 'Domain verification (optional)', status: 'current' },
  ]
 
 
  
  
export default function VerifyDomain() {

  const navigate = useNavigate()
  const [steps,setSteps] = useState(stepsVar)

  const [dkim,setDkim] :any = useState("")
  const [domain,setDomain] :any = useState("")

  const records = [
    { name: domain, height:8, type: 'TXT', value: 'v=spf1 include:mail.notificationharbor.com -all' },
    { name: "notificationharbor._domainkey."+domain, height:32, type: 'TXT', value: dkim },
  ];
  
  useEffect(() =>{
    // print domain and DKIM for user
    if (localStorage.getItem("dkim")){

      let dkimstring : any = localStorage.getItem("dkim")
      let raw = dkimstring?.split("v=DKIM1;")[1]
      raw = raw?.split(") ; ----- DKIM")[0]
      raw = '"v=DKIM1; '+raw
      raw = raw.replace(/(\r\n|\n|\r)/gm, "");
      raw = raw.replace(/\s/g,'')

      setDkim(raw)
  
      let domain = dkimstring.split(" ")[dkimstring.split(" ").length - 1]
      setDomain(domain)
    }
    
  },[])
 
  return (
   
    <div className='mx-10'>

    <nav aria-label="Progress" className='lg:w-1/2 mx-auto w-full'>
      <div className='mt-10'>
        <img alt="Logo" className='h-12' src={Logo}/>
      </div>
      <ol  className="mt-5 divide-y divide-gray-300 rounded-md border border-gray-500 md:flex md:divide-y-0">
      {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <div className="cursor-default group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600  ">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500">{step.name}</span>
                </span>
              </div>
            ) : step.status === 'current' ? (
              <div className="cursor-default flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
              </div>
            ) : (
              <div className="cursor-default group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-500 ">
                    <span className="text-gray-500 ">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 ">{step.name}</span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-500"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>

    <main className="mx-auto w-full lg:w-1/2 py-5 lg:pt-10">


        <form>
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-0">
            <h2 className="text-base font-semibold leading-7 text-white">DNS Configuration</h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              Please add the following records to your DNS-zone. Until added you can still send emails but they will be sent from hello@notificationharbor.com
            </p>
            

            <div className="mt-5 w-full text-white text-sm">
                <div className="flex border-b border-gray-500 bg-gray-800 p-4">
                    <div className="w-4/12 font-bold">Record</div>
                    <div className="w-1/12 font-bold">Type</div>
                    <div className="w-7/12 font-bold">Value</div>
                </div>
                {records.map((record, index) => (
                    <div key={index} className="flex border-b border-gray-500 p-4 text-xs">
                        <div className="w-5/12">{record.name}</div>
                        <div className="w-1/12">{record.type}</div>
                        <div className="w-6/12"><textarea className={`bg-gray-800 w-full rounded-sm text-sm p-1 h-${record.height}`} readOnly value={record.value}></textarea></div>
                    </div>
                ))}
            </div>

          </div> 
        </div>
  
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            onClick={() => {
              localStorage.removeItem("pendingDKIMVerification")
              navigate("/")
            }}
            type="submit"
            className="rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Skip setup
          </button>
          <button
            onClick={() => {
              localStorage.removeItem("pendingDKIMVerification")
              navigate("/")
            }}
            type="submit"
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
        </div>
      </form> 



    </main>
  
    
      
    </div>

  )
}
