import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'

import { TeamContext } from '../../../helpers/TeamContext';
import { useNavigate } from 'react-router-dom';
import { createEmail,SENDTYPES, SIDEBARNAVTYPE } from '../../../helpers/email/helper';
import Sidebar from '../../../components/email/Sidebar';
import EmailDisplay from '../../../components/email/EmailDisplay';


export default function NewCampaign() {

  const navigate = useNavigate();

  const {teamId,teamName,domain} = useContext(TeamContext)
 
  const [questionContextIntent,setQuestionContextIntent] : any = useState(false);  // used to toggle questionmark information above context intent
  const [sidebarNavigation,setSidebarNavigation] = useState<SIDEBARNAVTYPE>(SIDEBARNAVTYPE.default);  // used to toggle questionmark information above context intent

  // formdata
  

  const [campaignName,setCampaignName] = useState<string>("");
  const [intent,setIntent] = useState<string>("");
  const [sampleGeneratedEmailModel,setSampleGeneratedEmailModel] = useState<string>(""); // returned sample model by openai
  
  
  // send now area
  const [sendEmailNowLoader,setSendEmailNowLoader] = useState<boolean>(false);
  const [sendEmailNow,setSendEmailNow] = useState<boolean>(true);
  const [sendType,setSendType] : [SENDTYPES,Dispatch<SetStateAction<SENDTYPES>>] = useState<SENDTYPES>(SENDTYPES.singleEmail);
  const [singleEmailReceiver,setSingleEmailReceiver] = useState<string>("")
  const [customerSegment,setCustomerSegment] = useState<string>("")

  // set count for how many users will receive email
  const [sendNowCountReceivers, setSendNowCountReceivers] = useState<number>(1);
   




  return (
    <>

          <form onSubmit={(e)=>{
            e.preventDefault()
            createEmail({
              campaignName,
              intent,
              sampleGeneratedEmailModel,
              teamId,
              sendEmailNow,
              singleEmailReceiver,
              sendType,
              customerSegment,
              teamName,
              domain,
              setSendEmailNowLoader
            }).then((res) => {
              if (res===true){
                navigate("/emails")
              } else {
                alert(res)
              }
            })}} className="flex ">

         
        <Sidebar
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          intent={intent}
          setIntent={setIntent}
          questionContextIntent={questionContextIntent}
          setQuestionContextIntent={setQuestionContextIntent}
          sendEmailNow={sendEmailNow}
          setSendEmailNow={setSendEmailNow}
          sendType={sendType}
          setSendNowCountReceivers={setSendNowCountReceivers}
          setSendType={setSendType}
          sendNowCountReceivers={sendNowCountReceivers}
          singleEmailReceiver={singleEmailReceiver}
          customerSegment={customerSegment}
          setCustomerSegment={setCustomerSegment}
          setSingleEmailReceiver={setSingleEmailReceiver}
          sendEmailNowLoader={sendEmailNowLoader}
          setSampleGeneratedEmailModel={setSampleGeneratedEmailModel}
          canGenerateContext={true}
          setSidebarNavigation={setSidebarNavigation}
          sidebarNavigation={sidebarNavigation}
        />
          
          <EmailDisplay setSampleGeneratedEmailModel={setSampleGeneratedEmailModel} sampleGeneratedEmailModel={sampleGeneratedEmailModel} sidebarNavigation={sidebarNavigation}/>

        
        </form>
      
    </>
  )
}
