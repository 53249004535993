
import { useContext, useEffect, useState } from 'react';
import Header from '../../../components/layout/Header'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../../../helpers/supabase';
import { TeamContext } from '../../../helpers/TeamContext';
import { SubscriberList } from '../../../components/layout/SubscriberList';

import { UsersIcon } from '@heroicons/react/24/outline'
import ButtonDelete from '../../../components/input/ButtonDelete';

 
 
function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
}


export default function ViewCustomerSegment() {
  
    let { id } = useParams();  
    const navigate = useNavigate()

    const {teamId} = useContext(TeamContext)
    const [users,setUsers] : any = useState([]);
    const [customerSegment,setCustomerSegment] : any = useState(null);


    useEffect(() => {    
      async function getCustomerSegment(){
  
        if (!teamId){
          alert("Can't find teamId")
          return;
        }
  
  
        const customerSegment = await supabase.from("customer_segments").select("*").eq("team_id",teamId).eq("id",id).single()
        const {data} :any = await supabase.from("customer_segment_users")
                                      .select("subscriber (*)")
                                      .eq("team_id",teamId)
                                      .eq("customer_segment",id)
                                      .order("created_at",{ascending:false})

        const subscribers = data.map((item:any) => {
          // You might want to include the customer_segment in each subscriber if needed
          const subscriber = item.subscriber;
          subscriber.customer_segment = item.customer_segment;
          return subscriber;
        });

  
        setCustomerSegment(customerSegment.data)
        if (data !== null && data.length >= 1){
          setUsers(subscribers)
        }
      }
      getCustomerSegment()
      
    },[])


    if (customerSegment==null){
      return <p className='text-white'>Loading</p>
    }
  
  return (
    <>
    <Header title={`Customer segment`}>
      <Link to={`/customer-segments/manage/${id}/edit`}
        className="ml-auto rounded-md bg-transparent px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-slate-800 sm:block">
        Edit segment
      </Link>
      <ButtonDelete 
        onClick={() => {
          if (window.confirm("Do you want to delete this customer segment?") === true){
            try {
              supabase.from("customer_segments").delete().match({team_id: teamId, id: id}).then((res) => {
                navigate("/customer-segments")
              })
            } catch (e) {
              console.log(e)
            }
          }
        }}
      />
    </Header>

    <main className="mx-auto sm:px-8 lg:px-8">

      <div className='flex my-5'>

      <div
              className="basis-1/4 relative overflow-hidden rounded-lg bg-gray-950 px-4 pb-0 pt-5 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div className="absolute rounded-md bg-indigo-900 p-3">
                  <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">Members</p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-0 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-300">{users.length}</p>
                
              </dd>
            </div>

            <div className='basis-3/4 p-5 text-white'>
              <p className='text-md font-bold'>{customerSegment.name} #{customerSegment.id}</p>
              <p className='mt-2'>{customerSegment.description}</p>
            </div>
      </div>
    

    <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-left">
      
            <SubscriberList subscribers={users}/>


          </div>
        </div>
      </div>


    </main>
    </>
  )
}
