import { Handle, Position } from "reactflow";

  // not active
  export const IfElseNodeComponent = ({ data }:any) => {
    return (
      <div className="bg-transparent border border-gray-900 p-3 w-28 overflow-hidden text-center rounded-sm">
        {/* Entry handle */}
        <Handle type="target" position={Position.Top} style={{ borderRadius: 100 }} />
        <div className="text-xs">
          {data.label}
        </div>
        {/* Exit handles */}
        <Handle type="source" position={Position.Bottom} id="a" style={{ top: '90%', left:'30%', borderRadius: 100, background: '#555' }} />
        <Handle type="source" position={Position.Bottom} id="b" style={{ top: '90%', left:'70%', borderRadius: 100, background: '#555' }} />
      </div>
    );
  };
